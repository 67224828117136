import Cookies from "js-cookie";
import { useContext, useState } from "react";
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Loader,
  Message,
  Modal,
  Select,
} from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";
import SkinContext from "../../contexts/SkinContext";
import { getThumbnail, getTomorrowDate, getTradeLabelTags } from "../../utils";
import Error from "../Errors";
import ValidationError from "../Errors/ValidationError";
import ProductCard from "../ProductCard";
import InfoPopup from "../InfoPopup";

const ConfirmSection = ({
  isDarkMode,
  auctionDetail,
  setAuctionDetail,
  auctionError,
  setAuctionError,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const warrantyOptions = [
    { text: "24 hours", value: "1" },
    { text: "7 days", value: "7" },
    { text: "14 days", value: "14" },
    { text: "30 days", value: "30" },
  ];
  const maxRemarksLength = 30;

  const handleAuctionDetailChange = (e, { name, value, checked, type }) => {
    const inputValue = type === "checkbox" ? checked : value;
    setAuctionDetail((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));
    setAuctionError((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    if (type === "checkbox") {
      const resetField = name === "enableBid" ? "minimumBid" : "instantBuy";
      setAuctionDetail((prevState) => ({
        ...prevState,
        [resetField]: "",
      }));
      setAuctionError((prevState) => ({
        ...prevState,
        checkbox: "",
        [resetField]: "",
      }));
    }

    if (name === "minimumBid" || name === "instantBuy") {
      setAuctionError((prevState) => ({
        ...prevState,
        checkbox: "",
      }));
    }

    if (type === "date") {
      const currentDate = new Date();
      const datePicked = new Date(value);
      if (datePicked < currentDate) {
        setAuctionError((prevState) => ({
          ...prevState,
          endDate: "Valid Date range: 1-30 days",
        }));
      }
    }
  };

  return (
    <>
      <Header className="search-item" inverted={isDarkMode}>
        Do you want to add this account to Marketplace?
      </Header>
      <Message info>
        <Icon name="info circle" />A 10% commission will be applied to the final
        price.
      </Message>
      <Form inverted={isDarkMode}>
        <ValidationError error={auctionError.checkbox} />
        <Form.Checkbox
          label="Enable Bid"
          name="enableBid"
          checked={auctionDetail.enableBid}
          onChange={handleAuctionDetailChange}
        />
        <Form.Field>
          <label className="search-item">
            Minimum Bid{" "}
            <InfoPopup description={"Minimum amount that a buyer can bid."} />
          </label>
          <ValidationError error={auctionError.minimumBid} />
          <Input
            id="form-input-control-error-email"
            placeholder={"Minimum Bid Amount"}
            name="minimumBid"
            type={"number"}
            onChange={handleAuctionDetailChange}
            value={auctionDetail.minimumBid}
            disabled={!auctionDetail.enableBid}
            required
          />
        </Form.Field>
        <Form.Checkbox
          label="Enable Instant Buy"
          name="enableInstantBuy"
          checked={auctionDetail.enableInstantBuy}
          onChange={handleAuctionDetailChange}
        />
        <Form.Field>
          <label className="search-item">
            Insta Buy{" "}
            <InfoPopup
              description={
                "A buyer can instantly buy your account with this amount."
              }
            />
          </label>
          <ValidationError error={auctionError.instantBuy} />
          <Input
            placeholder={"Instant Buy Amount"}
            name="instantBuy"
            type={"number"}
            onChange={handleAuctionDetailChange}
            value={auctionDetail.instantBuy}
            disabled={!auctionDetail.enableInstantBuy}
          />
        </Form.Field>
        <Form.Field>
          <label>End Date</label>
          <ValidationError error={auctionError.endDate} />
          <Input
            placeholder={"Choose end date"}
            name="endDate"
            type={"date"}
            onChange={handleAuctionDetailChange}
            value={auctionDetail.endDate}
          />
        </Form.Field>
        <Form.Field>
          <label className="search-item">
            Warranty
            <InfoPopup
              description={
                "Your account will be credited at the end of the warranty period."
              }
            />
          </label>
          <Select
            options={warrantyOptions}
            name="warranty"
            onChange={handleAuctionDetailChange}
            value={auctionDetail.warranty}
          />
        </Form.Field>
        <Form.Field>
          <label>Enter the current account password</label>
          <ValidationError error={auctionError.currentPassword} />
          <Input
            placeholder={auctionDetail.currentPassword}
            icon={
              <Icon
                name={showPassword ? "eye" : "eye slash"}
                link
                onClick={() => setShowPassword(!showPassword)}
                color="grey"
              />
            }
            name="currentPassword"
            type={showPassword ? "text" : "password"}
            onChange={handleAuctionDetailChange}
            value={auctionDetail.currentPassword}
            autoComplete="new-password"
          />
        </Form.Field>
        <Form.Field>
          <label className="search-item">
            Add note (optional)
            <InfoPopup
              description={
                "Add a note to provide additional details about the account."
              }
            />
          </label>
          <Input
            placeholder="More info of account"
            value={auctionDetail.remarks}
            name="remarks"
            onChange={handleAuctionDetailChange}
            maxLength={maxRemarksLength}
          />
        </Form.Field>
      </Form>
    </>
  );
};

const ResponseSection = ({ isDarkMode }) => {
  return (
    <>
      <Header inverted={isDarkMode}>
        Your account has been added to marketplace.
      </Header>
    </>
  );
};

const isFormValid = (auctionDetail, auctionError, setAuctionError) => {
  setAuctionError({
    checkbox: "",
    minimumBid: "",
    instantBuy: "",
    endDate: "",
    warranty: "",
    currentPassword: "",
  });

  let isValid = true;
  // Condition: At least one of enableBid and enableInstantBuy must be enabled
  if (!auctionDetail.enableBid && !auctionDetail.enableInstantBuy) {
    setAuctionError((prevState) => ({
      ...prevState,
      checkbox: "At least one field, Bid or Instant Buy, must be enabled",
    }));
    isValid = false;
  } else if (
    auctionDetail.enableBid &&
    auctionDetail.enableInstantBuy &&
    Number(auctionDetail.minimumBid) >= Number(auctionDetail.instantBuy)
  ) {
    setAuctionError((prevState) => ({
      ...prevState,
      checkbox: "Minimum Bid Amount must be less than Instant Buy Amount",
    }));
    isValid = false;
  }

  // Condition: minimumBid and instantBuy should be greater than zero if enabled
  if (auctionDetail.enableBid && Number(auctionDetail.minimumBid) <= 0) {
    setAuctionError((prevState) => ({
      ...prevState,
      minimumBid: "Amount must be greater than zero",
    }));
    isValid = false;
  }

  if (auctionDetail.enableInstantBuy && Number(auctionDetail.instantBuy) <= 0) {
    setAuctionError((prevState) => ({
      ...prevState,
      instantBuy: "Amount must be greater than zero",
    }));
    isValid = false;
  }

  // Condition: EndDate is required and should be between 1 day and 1 month from today
  if (!auctionDetail.endDate) {
    setAuctionError((prevState) => ({
      ...prevState,
      endDate: "Field is required",
    }));
    isValid = false;
  } else {
    const currentDate = new Date();
    const oneDayFromToday = new Date(currentDate);
    oneDayFromToday.setDate(currentDate.getDate() + 1);
    const oneMonthFromToday = new Date(currentDate);
    oneMonthFromToday.setMonth(currentDate.getMonth() + 1);

    // Set time parts to 00:00:00 to compare only the dates
    currentDate.setHours(0, 0, 0, 0);
    oneDayFromToday.setHours(0, 0, 0, 0);
    oneMonthFromToday.setHours(0, 0, 0, 0);
    const selectedDate = new Date(auctionDetail.endDate);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate < oneDayFromToday || selectedDate > oneMonthFromToday) {
      setAuctionError((prevState) => ({
        ...prevState,
        endDate: "Valid Date range: 1-30 days",
      }));
      isValid = false;
    }
  }
  // Condition: warranty is required
  if (!auctionDetail.warranty) {
    setAuctionError((prevState) => ({
      ...prevState,
      warranty: "Field is required",
    }));
    isValid = false;
  }

  // Condition: currentPassword is required
  if (!auctionDetail.currentPassword) {
    setAuctionError((prevState) => ({
      ...prevState,
      currentPassword: "Field is required",
    }));
    isValid = false;
  }
  return isValid;
};

export const AuctionModal = ({
  setOpen,
  product,
  refreshBalance,
  refreshTable,
}) => {
  const { isDarkMode } = useContext(DarkModeContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [auctionDetail, setAuctionDetail] = useState({
    enableBid: true,
    minimumBid: 5,
    enableInstantBuy: false,
    instantBuy: "",
    endDate: getTomorrowDate(),
    warranty: "1",
    currentPassword: product.password,
    remarks: "",
  });
  const [auctionError, setAuctionError] = useState({
    checkbox: "",
    minimumBid: "",
    instantBuy: "",
    endDate: "",
    warranty: "",
    currentPassword: "",
  });

  const [label_tags] = getTradeLabelTags(product);
  product["label_tags"] = label_tags;
  const { skinShards } = useContext(SkinContext);
  const productSkins = [
    ...product.skins,
    ...product.permanentSkins,
    ...product.ownedSkins,
  ];
  const [thumbnail] = getThumbnail(skinShards, productSkins);
  product["thumbnail"] = thumbnail;

  const [currentProduct] = useState(product);
  const getSection = (currentStep) => {
    switch (currentStep) {
      case 0:
        return (
          <ConfirmSection
            isDarkMode={isDarkMode}
            product={currentProduct}
            auctionDetail={auctionDetail}
            setAuctionDetail={setAuctionDetail}
            auctionError={auctionError}
            setAuctionError={setAuctionError}
          />
        );
      case 1:
        return <ResponseSection isDarkMode={isDarkMode} />;
      default:
        return null;
    }
  };

  const auctionAccount = async () => {
    setErrors({});

    function getDaysFromDate(endDate) {
      const currentDate = new Date();
      const [year, month, day] = endDate.split("-");
      const endDateTime = new Date(year, month - 1, day);
      const difference = Math.ceil(
        (endDateTime - currentDate) / (1000 * 60 * 60 * 24),
      );
      return difference;
    }

    try {
      setLoading(true);
      const response = await fetch(
        `/api/marketplace/products/${product.username}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
          body: JSON.stringify({
            product: product.username,
            password: auctionDetail.currentPassword,
            ...(auctionDetail.minimumBid && {
              minimumBidAmount: Number(auctionDetail.minimumBid),
            }),
            ...(auctionDetail.instantBuy && {
              instaBuyAmount: Number(auctionDetail.instantBuy),
            }),
            endDays: getDaysFromDate(auctionDetail.endDate),
            warrantyDays: Number(auctionDetail.warranty),
            remarks: auctionDetail.remarks,
          }),
        },
      );
      const data = await response.json();
      if (response.ok) {
        setSuccess(true);
        setErrors({});
        setCurrentStep(1);
      } else {
        setErrors(data);
      }
    } catch (reason) {
      setErrors({
        error: "Error connecting to server. Please try again later.",
      });
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setCurrentStep(0);
    if (currentStep === 1) {
      refreshTable();
      refreshBalance();
    }
    setOpen(false);
  };

  const handleProceedClick = () => {
    if (isFormValid(auctionDetail, auctionError, setAuctionError)) {
      auctionAccount();
    }
  };

  return (
    <Modal
      open
      closeOnDimmerClick={false}
      className={`trade-modal${isDarkMode ? " dark" : ""}`}
      data-theme={isDarkMode ? "dark" : "light"}
      closeIcon
      onClose={handleModalClose}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>Add Account to Marketplace</Modal.Header>
      <Modal.Content image>
        <ProductCard product={currentProduct} isTradeCard={true} />
        <Modal.Description>
          {!loading && getSection(currentStep)}
          {loading && (
            <Loader
              className={`trade-modal-loader ${isDarkMode ? " dark" : "light"}`}
              active
              inline="centered"
            >
              Processing the request. Please wait...
            </Loader>
          )}
          <Error errors={errors} />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className="trade-modal-actions">
        {currentStep === 0 ? (
          <>
            <Button
              color="red"
              onClick={handleModalClose}
              disabled={success || loading}
            >
              Cancel
            </Button>
            <Button
              color="blue"
              onClick={handleProceedClick}
              disabled={success || loading}
            >
              Proceed
            </Button>
          </>
        ) : (
          <Button
            color="blue"
            onClick={handleModalClose}
            disabled={loading}
            icon
            labelPosition="right"
          >
            Finish
            <Icon name="check" />
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
