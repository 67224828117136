import React, { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Button, Container, Message } from "semantic-ui-react";
import CartContext from "../contexts/CartContext";

const useQuery = () => new URLSearchParams(useLocation().search);

export default function PurchaseFailed() {
  const history = useHistory();
  const query = useQuery();

  const message =
    query.get("detail") ?? "Your purchase could not be completed successfully.";

  const { refreshCart } = useContext(CartContext);

  useEffect(() => refreshCart(), [refreshCart]);

  return (
    <Container style={{ padding: "1em" }}>
      <Message error>
        {message} If the problem persists, contact the support team.
      </Message>
      <Button onClick={() => history.push({ pathname: "/my-cart/" })}>
        Go to my cart
      </Button>
    </Container>
  );
}
