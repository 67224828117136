import { useContext, useEffect } from "react";
import { Container, Divider, Grid, Segment } from "semantic-ui-react";
import { displayName } from "../constants";
import DarkModeContext from "../contexts/DarkModeContext";
import LoginForm from "./LoginForm";
import RedirectOnAuthSuccess from "./RedirectOnAuthSuccess";
import RegisterForm from "./RegisterForm";

function MyAccountPage() {
  const { isDarkMode } = useContext(DarkModeContext);

  useEffect(() => (document.title = `${displayName} | Login/Register`));
  return (
    <RedirectOnAuthSuccess>
      <Segment className="my-account-segment" basic>
        <Container>
          <Grid
            className="my-account-grid"
            columns={2}
            relaxed="very"
            stackable
          >
            <Divider vertical inverted={isDarkMode}>
              OR
            </Divider>
            <Grid.Row>
              <Grid.Column>
                <LoginForm />
              </Grid.Column>
              <Grid.Column>
                <RegisterForm />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Segment>
    </RedirectOnAuthSuccess>
  );
}

export default MyAccountPage;
