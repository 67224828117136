import Cookies from "js-cookie";
import React, { useContext, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, Form, Header, Message, Segment } from "semantic-ui-react";
import { captchaSiteKey } from "../constants";
import DarkModeContext from "../contexts/DarkModeContext";

export default function ForgotPasswordPage() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState();
  const [success, setSuccess] = useState(false);
  const captchaRef = useRef();

  const { isDarkMode } = useContext(DarkModeContext);

  const forgotPassword = async () => {
    try {
      setLoading(true);
      const response = await fetch("/api/auth/password/reset/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({ email, captcha: captcha || "" }),
      });
      captchaRef.current?.reset();
      setCaptcha();
      if (response.status === 429) {
        setSuccess(false);
        setErrors({ email: "Too many attempts. Please slow down." });
        return;
      }
      if (response.ok) {
        setSuccess(true);
        setErrors({});
        return;
      }
      setSuccess(false);
      setErrors(await response.json());
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="reset-password-container">
      <Segment style={{ width: "400px" }} basic>
        <Header textAlign="center" as="h2" color="teal">
          Reset Your Password
        </Header>
        <Form
          inverted={isDarkMode}
          success={success}
          loading={loading}
          onSubmit={forgotPassword}
        >
          <Form.Input
            icon="user"
            iconPosition="left"
            label="Email"
            placeholder="Email"
            type="email"
            value={email}
            error={errors.email}
            onChange={(e, { value }) => setEmail(value)}
            required
          />
          <Message success>
            <Message.Header>Success</Message.Header>
            Password reset e-mail has been sent.
          </Message>
          {captchaSiteKey && (
            <ReCAPTCHA
              className="recaptcha"
              ref={captchaRef}
              sitekey={captchaSiteKey}
              onChange={(value) => setCaptcha(value)}
            />
          )}
          <Button type="submit" disabled={captchaSiteKey && !captcha}>
            Reset Password
          </Button>
        </Form>
      </Segment>
    </div>
  );
}
