import React, { useContext, useState } from "react";
import { Form, Header, Icon, Input, Menu } from "semantic-ui-react";
import { useHistory, useRouteMatch, useLocation } from "react-router-dom";
import DarkModeContext from "../../contexts/DarkModeContext";
import { AllPurchases } from "../../components/AllPurchases";
import { TradedAccounts } from "../../components/TradedAccounts";
import { HiddenAccounts } from "./HiddenAccounts";

const useQuery = () => new URLSearchParams(useLocation().search);

export const YourPurchases = ({ refreshBalance }) => {
  const { isDarkMode } = useContext(DarkModeContext);
  const query = useQuery();
  const [searchText, setSearchText] = useState(query.get("search"));
  const history = useHistory();
  const location = useLocation();
  const isTradedAccounts = useRouteMatch(
    "/my-account/profile/your-purchases/traded-accounts/",
  );
  const isHiddenAccounts = useRouteMatch(
    "/my-account/profile/your-purchases/hidden-accounts/",
  );
  const isAllPurchases = !(isTradedAccounts || isHiddenAccounts);
  const getActive = () => {
    if (isTradedAccounts) return "traded-accounts";
    if (isHiddenAccounts) return "hidden-accounts";
    return "all-purchases";
  };
  const [activeItem, setActiveItem] = useState(getActive());

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    query.set("page", 1);
    query.set("search", "");
    setSearchText("");
    setSearch(`?${query.toString()}`);
    history.push({
      pathname: `/my-account/profile/your-purchases/${name}/`,
      search: query.toString(),
    });
  };

  const [search, setSearch] = useState(() => {
    // Set page to 1 if not initialized
    if (!query.get("page")) {
      query.set("page", 1);
    }
    // Set limit to 5 if not initialized
    if (!query.get("limit")) {
      query.set("limit", 5);
    }
    return `?${query.toString()}`;
  });

  const handleSearchChange = () => {
    query.set("search", searchText);
    query.set("page", 1);
    history.replace({ pathname: location.pathname, search: query.toString() });
    setSearch(`?${query.toString()}`);
  };

  return (
    <div className="your-purchases">
      <div className="your-purchases-header">
        <Menu className="your-purchases-tabs" text inverted={isDarkMode}>
          <Menu.Item
            name="all-purchases"
            active={activeItem === "all-purchases"}
            onClick={handleItemClick}
          >
            <Header inverted={isDarkMode}>All Purchases</Header>
          </Menu.Item>
          <Menu.Item
            name="traded-accounts"
            active={activeItem === "traded-accounts"}
            onClick={handleItemClick}
          >
            <Header inverted={isDarkMode}>Traded Accounts</Header>
          </Menu.Item>
          <Menu.Item
            name="hidden-accounts"
            active={activeItem === "hidden-accounts"}
            onClick={handleItemClick}
          >
            <Header inverted={isDarkMode}>Hidden Accounts</Header>
          </Menu.Item>
        </Menu>
        {(isAllPurchases || isHiddenAccounts) && (
          <Form onSubmit={handleSearchChange}>
            <Input
              inverted
              type="text"
              placeholder="Search using skin name"
              value={searchText ? searchText : ""}
              onChange={(e) => setSearchText(e.target.value)}
              icon={<Icon name="search" color="grey" />}
              className={`purchased-product-search${isDarkMode ? " dark" : ""}`}
            />
          </Form>
        )}
      </div>
      {isAllPurchases ? (
        <AllPurchases
          refreshBalance={refreshBalance}
          search={search}
          setSearch={setSearch}
        />
      ) : isTradedAccounts ? (
        <TradedAccounts search={search} setSearch={setSearch} />
      ) : isHiddenAccounts ? (
        <HiddenAccounts
          refreshBalance={refreshBalance}
          search={search}
          setSearch={setSearch}
        />
      ) : null}
    </div>
  );
};
