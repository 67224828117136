import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Header, Message, Segment } from "semantic-ui-react";
import CartContext from "../contexts/CartContext";
import DarkModeContext from "../contexts/DarkModeContext";

function LoginForm() {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const { isDarkMode } = useContext(DarkModeContext);
  const { refreshCart } = useContext(CartContext);

  const loginStatus = async (queue_id) => {
    // Return whether to break or keep polling
    try {
      const response = await fetch(`/api/login/status/${queue_id}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.user) {
          refreshCart();
          history.push({
            pathname: "/my-account/profile/your-purchases/",
            search: "?page=1&limit=5",
          });
          return false;
        }
        setErrors(data);
        return true;
      }
      const data = await response.json();
      setErrors(data);
      setLoading(false);
      return false;
    } catch (reason) {
      console.log(reason);
      setLoading(false);
    }
    return false;
  };

  const login = async () => {
    try {
      setLoading(true);
      setErrors({});
      const response = await fetch("/api/login/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const queueId = data.queueId;
        if (!queueId) {
          setLoading(false);
          return;
        }
        while (true) {
          if (!(await loginStatus(queueId))) return;
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
      const data = await response.json();
      setErrors(data);
      setLoading(false);
    } catch (reason) {
      console.log(reason);
      setLoading(false);
    }
  };

  return (
    <>
      <Header as="h2" color="teal" textAlign="center">
        Login to your account
      </Header>
      <Segment basic>
        <Form
          onSubmit={login}
          inverted={isDarkMode}
          size="large"
          error={
            errors.nonFieldErrors !== undefined || errors.detail !== undefined
          }
        >
          <Form.Input
            icon="user"
            iconPosition="left"
            label="Email"
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e, { value }) => setEmail(value)}
            error={errors.email}
            required
          />
          <Form.Input
            icon="lock"
            iconPosition="left"
            label="Password"
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e, { value }) => setPassword(value)}
            error={errors.password}
            required
          />
          <Message
            error
            header="Login Error"
            content={errors.nonFieldErrors || errors.detail}
          />

          <Button
            loading={loading}
            color="teal"
            size="large"
            type="submit"
            disabled={loading}
          >
            Login
          </Button>
          <Button
            size="large"
            onClick={() => history.push("/my-account/forgot-password/")}
          >
            Forgot Password
          </Button>
        </Form>
      </Segment>
    </>
  );
}

export default LoginForm;
