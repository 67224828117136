import React from "react";

const CartContext = React.createContext({
  cartItems: [],
  loadingCartItems: false,
  refreshCart: () => {},
  setCartItems: () => {},
});

export default CartContext;
