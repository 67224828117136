import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Message, Segment } from "semantic-ui-react";

export default function ConfirmEmail() {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const params = useRef(useParams());
  const history = useRef(useHistory());

  const confirmEmail = async (signal) => {
    try {
      setLoading(true);
      const response = await fetch("/api/auth/registration/verify-email/", {
        method: "POST",
        signal,
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({ key: params.current.key }),
      });
      if (response.ok) {
        setSuccess(true);
        setError(false);
        return;
      }
      if (response.status === 429) {
        history.push("/429/");
      }
      setSuccess(false);
      setError(true);
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    confirmEmail(controller.signal);
    return () => controller.abort();
  }, []);

  return (
    <div className="reset-password-container">
      <Segment style={{ width: "400px" }} basic>
        <Form loading={loading} success={success} error={error}>
          <Message success>
            <Message.Header>Success</Message.Header>
            Your email has been successfully confirmed.
          </Message>
          <Message error>
            <Message.Header>Error</Message.Header>
            There was an error when confirming your email.
          </Message>
        </Form>
      </Segment>
    </div>
  );
}
