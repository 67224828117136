import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Icon, Message } from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";

const errorMessage =
  "Some error occured when validating the provided discount code. " +
  "Please make sure that the coupon code is entered correctly and is still valid.";

export default function ValidateDiscountCoupon({
  cartItems,
  onSuccess,
  onClear,
  loading,
  setLoading,
}) {
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState();
  const [success, setSuccess] = useState(false);
  const [validateError, setValidateError] = useState();
  const [disabled, setDisabled] = useState(false);
  const { isDarkMode } = useContext(DarkModeContext);
  const history = useHistory();

  const validateCoupon = async () => {
    setSuccess(false);
    setCodeError(null);
    setValidateError(null);
    setDisabled(false);

    if (code === "" || code === null) {
      setCodeError("This field may not be blank.");
      return;
    }
    try {
      setLoading(true);
      const response = await fetch(`/api/discount-coupon/validate/${code}/`);
      if (response.status === 429) {
        history.push("/429/");
      }
      if (response.ok) {
        setSuccess(true);
        setCodeError(null);
        setValidateError(null);
        setDisabled(true);
        onSuccess(await response.json());
      } else if (response.status === 400) {
        const json = await response.json();
        setValidateError(json.detail);
      } else {
        setValidateError(errorMessage);
      }
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      inverted={isDarkMode}
      className="fluid attached"
      success={success}
      error={validateError != null}
    >
      <Form.Group className="discount-code-form">
        <Form.Input
          className="discount-code"
          disabled={disabled}
          placeholder="Enter discount code"
          type="text"
          onChange={(e, { value }) => {
            setCode(value);
            setCodeError(null);
          }}
          value={code}
          error={codeError}
        />
        <Form.Button
          color="twitter"
          onClick={validateCoupon}
          disabled={disabled}
          loading={loading}
        >
          Apply
        </Form.Button>
      </Form.Group>
      <Message success>
        Using discount code: {code}
        <Icon
          name="close"
          onClick={() => {
            setSuccess(false);
            setCode("");
            setDisabled(false);
            onClear();
          }}
        />
      </Message>
      <Message info>
        <Icon name="info circle" />
        Discount code is not applicable for ranked and handleveled accounts.
      </Message>
      {validateError && (
        <Message error>
          <Icon name="info circle" />
          {validateError}
        </Message>
      )}
    </Form>
  );
}
