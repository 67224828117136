import { Icon, Message } from "semantic-ui-react";
import { ParamModal } from "./Modal/ParamModal";

export const ParamLoadBalanceModal = ({ open, setOpen, amount }) => {
  return (
    <ParamModal
      open={open}
      setOpen={setOpen}
      paramAmount={Number(amount)}
      url="/api/load-balance/card/"
      payload={{ amount }}
      header="Load Balance"
      content="Balance Summary:"
      description="Load balance using credit card"
      promoComponent={
        <Message success>
          <Icon name="info circle" />
          You will receive 10% bonus balance on successful payment.
        </Message>
      }
    />
  );
};
