import React, { useContext } from "react";
import { Icon, Label, LabelGroup, Popup, Segment } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";
import { getRegion } from "../utils";
import AddToCartPopup from "./AddToCart";
import InfoPopup from "./InfoPopup";
import ShowSkinsPopup from "./ShowSkinsPopup";
import { rankedValues } from "../constants";

export default function ProductCard({ product, isTradeCard = false }) {
  const { isDarkMode } = useContext(DarkModeContext);
  const blueEssence = isTradeCard
    ? product.blueEssence
    : product.discreteBlueEssence;

  const renderIconPopups = () => (
    <div className="product-card-icons">
      {product.tags?.map((tag, index) => (
        <Popup
          key={index}
          trigger={
            tag.img ? (
              <img src={tag.img} className="product-icon" alt="icon" />
            ) : (
              <Icon
                className="product-icon"
                name={tag.icon || "tag"}
                style={{ color: tag.color || "#fdde81" }}
                size="large"
              />
            )
          }
          content={tag.description}
        />
      ))}
    </div>
  );
  const renderLabelTags = () => (
    <LabelGroup className="product-card-labels">
      {product.label_tags?.map((tag, index) => {
        if (tag.description) {
          return (
            <Popup
              key={index}
              trigger={
                <Label key={index} className={tag.color}>
                  {tag.icon && <Icon name={tag.icon} />}
                  {tag.label}
                </Label>
              }
              content={tag.description}
            />
          );
        }

        return (
          <Label key={index} className={tag.color}>
            {tag.icon && <Icon name={tag.icon} />}
            {tag.label}
          </Label>
        );
      })}
    </LabelGroup>
  );

  return (
    <Segment.Group className={`product-card ${isDarkMode ? "dark-mode" : ""}`}>
      <Segment
        basic
        inverted={isDarkMode}
        className="product-card-image-wrapper "
      >
        <div className="product-card-icons-wrapper">
          {renderLabelTags()}
          {!isTradeCard && renderIconPopups()}
        </div>
        <div
          className="product-card-image"
          style={{
            backgroundImage: `linear-gradient(180deg, var(--gradient) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 80%, var(--gradient) 100%), url(${product.thumbnail})`
          }}
        ></div>
      </Segment>
      <Segment inverted={isDarkMode} className="product-details">
        <div className="product-header">
          <div className="product-description">
            {isTradeCard && <div>Username: {product.username}</div>}
            <div>Region: {getRegion(product.region)}</div>
            <div>Level: {product.discreteLevel || 30}+</div>
            <div className="be">
              Blue Essence:
              {blueEssence <= 0
                ? " <10,000"
                : " " + blueEssence.toLocaleString() + "+"}
              {product.isHandleveled ? (
                <InfoPopup description="This account contains champion capsules." />
              ) : null}
            </div>
            <div>Orange Essence: {product.orangeEssence.toLocaleString()}</div>
            {rankedValues.map((r) => r.slice(0, 2)).includes(product.rank) && (
              <div>
                Ranked Wins/Losses: {product.soloWins || " - "}/
                {product.soloLosses || " - "}
              </div>
            )}
          </div>
          {!isTradeCard && (
            <div className="price">
              {product.price.toLocaleString(undefined, {
                style: "currency",
                currency: "EUR",
              })}
            </div>
          )}
        </div>
      </Segment>
      <Segment className="product-card-buttons" inverted={isDarkMode} basic>
        <ShowSkinsPopup
          skins={product.skins}
          permanent_skins={product.permanentSkins}
          owned_skins={product.ownedSkins}
          relevantSkins={product.relevantSkins}
        />
        {!isTradeCard && <AddToCartPopup product={product} />}
      </Segment>
    </Segment.Group>
  );
}
