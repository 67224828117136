import React, { useState, useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Icon, Label, Menu, Segment } from "semantic-ui-react";
import useWindowDimensions from "../../hooks/useWIndowDimensions";
import { mythicAccountsLink } from "../../constants";
import CartContext from "../../contexts/CartContext";
import DarkModeContext from "../../contexts/DarkModeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import HamMenu from "./HamMenu";
import "./index.css";
const discordLink = process.env.REACT_APP_DISCORD_LINK;

export default function NavigationBar({
  allowDarkMode,
  displayLogoTitle,
  isAuctionEnabled,
}) {
  const [menu, setMenu] = useState(false);
  let history = useHistory();
  let buyAccountMatch = useRouteMatch("/buy-account/");
  let marketplaceMatch = useRouteMatch("/marketplace/");
  let myAccountMatch = useRouteMatch("/my-account/");
  let faqMatch = useRouteMatch("/faq/");
  let tosMatch = useRouteMatch("/tos/");
  let cartMatch = useRouteMatch("/my-cart/");

  const { cartItems } = useContext(CartContext);
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);

  const getActive = () => {
    if (buyAccountMatch) return "buy-account";
    if (marketplaceMatch) return "marketplace";
    if (myAccountMatch) return "my-account";
    if (faqMatch) return "faq";
    if (tosMatch) return "tos";
    if (cartMatch) return "cart";
    return "home";
  };

  const windowWidth = useWindowDimensions().width;

  return (
    <Segment basic className="nav-wrapper">
      <Menu inverted pointing secondary stackable className="nav">
        {displayLogoTitle && (
          <Menu.Menu position="left">
            <Menu.Item header>
              <div className="nav-logo-title">
                <div
                  className="smurfskins-logo-title"
                  onClick={() => history.push("/")}
                >
                  <img
                    className="logo"
                    src="/static/images/logo-title.png"
                    alt="banner"
                  />
                </div>
              </div>
            </Menu.Item>
          </Menu.Menu>
        )}

        {windowWidth < 950 && displayLogoTitle && (
          <Menu.Menu position="right">
            <FontAwesomeIcon
              icon={menu ? faXmark : faBars}
              size="2x"
              style={{ color: "#d1d5db", cursor: "pointer" }}
              onClick={() => {
                setMenu((prev) => !prev);
              }}
            />
          </Menu.Menu>
        )}

        {windowWidth < 768 && !displayLogoTitle && (
          <Menu.Menu position="right">
            <FontAwesomeIcon
              icon={menu ? faXmark : faBars}
              size="2x"
              style={{ color: "#d1d5db", cursor: "pointer" }}
              onClick={() => {
                setMenu((prev) => !prev);
              }}
            />
          </Menu.Menu>
        )}

        {windowWidth > 949 && displayLogoTitle && (
          <Menu.Menu position="right">
            <Menu.Item
              active={getActive() === "cart"}
              onClick={() => history.push("/my-cart/")}
            >
              <Icon name="shop" /> My Cart
              {cartItems.length >= 1 && (
                <Label floating size="mini" circular color="teal">
                  {cartItems.length}
                </Label>
              )}
            </Menu.Item>
            <Menu.Item
              name="Home"
              active={getActive() === "home"}
              onClick={() => history.push("/")}
            />
            <Menu.Item
              name="Buy Account"
              active={getActive() === "buy-account"}
              onClick={() => history.push("/buy-account/")}
            />
            {isAuctionEnabled && (
              <Menu.Item
                active={getActive() === "marketplace"}
                onClick={() => history.push("/marketplace/")}
              >
                Marketplace
                <Label
                  floating
                  size="mini"
                  color="teal"
                  style={{
                    left: "7rem",
                    width: "fit-content",
                  }}
                >
                  Beta
                </Label>
              </Menu.Item>
            )}
            <Menu.Item
              name="My Account"
              active={getActive() === "my-account"}
              onClick={() =>
                history.push({
                  pathname: "/my-account/profile/your-purchases/",
                  search: "?page=1&limit=5",
                })
              }
            />
            {!displayLogoTitle && (
              <Menu.Item
                name="FAQ"
                active={getActive() === "faq"}
                onClick={() => history.push("/faq/")}
              />
            )}
            {discordLink ? (
              <Menu.Item
                name="Join Discord"
                active={false}
                onClick={() => window.open(discordLink, "_blank")}
              />
            ) : null}
            {allowDarkMode && (
              <Menu.Item onClick={() => toggleDarkMode(!isDarkMode)}>
                <Icon name="moon" color={isDarkMode ? "teal" : "white"} />
              </Menu.Item>
            )}
          </Menu.Menu>
        )}

        {windowWidth >= 767 && !displayLogoTitle && (
          <Menu.Menu position="right">
            <Menu.Item
              active={getActive() === "cart"}
              onClick={() => history.push("/my-cart/")}
            >
              <Icon name="shop" /> My Cart
              {cartItems.length >= 1 && (
                <Label floating size="mini" circular color="teal">
                  {cartItems.length}
                </Label>
              )}
            </Menu.Item>
            <Menu.Item
              name="Home"
              active={getActive() === "home"}
              onClick={() => history.push("/")}
            />
            <Menu.Item
              name="Buy Account"
              active={getActive() === "buy-account"}
              onClick={() => history.push("/buy-account/")}
            />
            {isAuctionEnabled && (
              <Menu.Item
                active={getActive() === "marketplace"}
                onClick={() => history.push("/marketplace/")}
              >
                Marketplace
                <Label
                  floating
                  size="mini"
                  color="teal"
                  style={{
                    left: "7rem",
                    width: "fit-content",
                  }}
                >
                  Beta
                </Label>
              </Menu.Item>
            )}
            <Menu.Item
              name="My Account"
              active={getActive() === "my-account"}
              onClick={() =>
                history.push({
                  pathname: "/my-account/profile/your-purchases/",
                  search: "?page=1&limit=5",
                })
              }
            />
            <Menu.Item
              name="FAQ"
              active={getActive() === "faq"}
              onClick={() => history.push("/faq/")}
            />
            {discordLink ? (
              <Menu.Item
                name="Join Discord"
                active={false}
                onClick={() => window.open(discordLink, "_blank")}
              />
            ) : null}
            {allowDarkMode && (
              <Menu.Item onClick={() => toggleDarkMode(!isDarkMode)}>
                <Icon name="moon" color={isDarkMode ? "teal" : "white"} />
              </Menu.Item>
            )}
          </Menu.Menu>
        )}
      </Menu>
      {menu && windowWidth < 950 && (
        <HamMenu
          getActive={getActive}
          cartItems={cartItems}
          displayLogoTitle={displayLogoTitle}
          isDarkMode={isDarkMode}
          toggleDarkMode={toggleDarkMode}
          allowDarkMode={allowDarkMode}
          setMenu={setMenu}
          isAuctionEnabled={isAuctionEnabled}
        />
      )}
    </Segment>
  );
}
