import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { YourPurchases } from "../pages/UserProfile";
import ChangePassword from "./ChangePassword";
import ExportData from "./ExportData";
import LoadBalance from "./LoadBalance";
import RedeemCoupon from "./RedeemCoupon";
import RedirectOnAuthFail from "./RedirectOnAuthFail";
import TransferBalance from "./TransferBalance";
import TransferBalanceCrossSite from "./TransferBalanceCrossSite";
import UserProfileSidebar from "./UserProfileSidebar";
import YourTransactions from "./YourTransactions";
import YourPayments from "./YourPayments";

export default function UserProfile() {
  const [email, setEmail] = useState("Loading email...");
  const [balanceTransferAllowed, setBalanceTransferAllowed] = useState(false);

  const isYourPurchases = useRouteMatch("/my-account/profile/your-purchases");
  const isYourTransactions = useRouteMatch(
    "/my-account/profile/your-transactions",
  );
  const isYourPayments = useRouteMatch("/my-account/profile/your-payments");
  const isChangePassword = useRouteMatch("/my-account/profile/change-password");
  const isExportData = useRouteMatch("/my-account/profile/export-data");
  const isRedeemCoupon = useRouteMatch("/my-account/profile/redeem-coupon");
  const isLoadBalance = useRouteMatch("/my-account/profile/load-balance");
  const isTransferBalance = useRouteMatch(
    "/my-account/profile/transfer-balance",
  );
  const isTransferBalanceCrossSite = useRouteMatch(
    "/my-account/profile/transfer-balance-cross-site",
  );
  const history = useRef(useHistory());

  const [balance, setBalance] = useState("Loading balance...");

  const getUserBalance = useCallback(async () => {
    try {
      const response = await fetch("/api/user/balance/");
      if (response.status === 429) {
        history.current.push("/429/");
      }
      if (response.ok) {
        const json = await response.json();
        setBalance(parseFloat(json.balance));
        return;
      }
    } catch (reason) {
      if (reason.name === "AbortError") return;
      console.log(reason);
    }
  }, []);

  const getUser = useCallback(
    async (signal) => {
      try {
        const response = await fetch("/api/auth/user/", { signal });
        if (response.status === 429) {
          history.current.push("/429/");
        }
        if (response.ok) {
          const json = await response.json();
          setEmail(json.email);
          setBalanceTransferAllowed(json.isBalanceTransferAllowed);
          getUserBalance();
          return;
        }
      } catch (reason) {
        if (reason.name === "AbortError") return;
        console.log(reason);
      }
    },
    [getUserBalance],
  );

  useEffect(() => {
    const controller = new AbortController();
    getUser(controller.signal);
    return () => controller.abort();
  }, [getUser]);

  return (
    <RedirectOnAuthFail>
      <div className="user-profile">
        <UserProfileSidebar
          balance={balance}
          email={email}
          balanceTransferAllowed={balanceTransferAllowed}
        />
        <div className="user-profile-content">
          {isYourPurchases ? (
            <YourPurchases refreshBalance={getUserBalance} />
          ) : isYourTransactions ? (
            <YourTransactions />
          ) : isYourPayments ? (
            <YourPayments />
          ) : isChangePassword ? (
            <ChangePassword />
          ) : isExportData ? (
            <ExportData />
          ) : isRedeemCoupon ? (
            <RedeemCoupon refreshBalance={getUserBalance} />
          ) : isLoadBalance ? (
            <LoadBalance />
          ) : isTransferBalance && balanceTransferAllowed ? (
            <TransferBalance refreshBalance={getUserBalance} />
          ) : isTransferBalanceCrossSite ? (
            <TransferBalanceCrossSite refreshBalance={getUserBalance} />
          ) : null}
        </div>
      </div>
    </RedirectOnAuthFail>
  );
}
