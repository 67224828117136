import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import NoResultsFound from "../../components/NoResultsFound";
import ProductPaginator from "../../components/ProductPaginator";
import { AllPurchasesTable } from "../../components/AccountsTable";

const path = "/my-account/profile/your-purchases/hidden-accounts";

export const HiddenAccounts = ({ refreshBalance, search, setSearch }) => {
  const history = useHistory();
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState({ results: [] });

  const refreshTable = () => {
    setRefetch(!refetch);
  };
  const fetchPurchases = useCallback(
    async (signal) => {
      try {
        setLoading(true);
        const response = await fetch(
          "/api/products/purchased/" + search + "&is_hidden=true",
          {
            signal,
          },
        );
        if (response.status === 429) {
          history.push("/429/");
        }
        if (response.ok) {
          const json = await response.json();
          setProducts(json);
        }
        setLoading(false);
      } catch (reason) {
        if (reason.name === "AbortError") return;
        setLoading(false);
        console.log(reason);
      }
    },
    [search, history],
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchPurchases(controller.signal);
    return () => controller.abort();
  }, [fetchPurchases, refetch]);

  const noResults = loading || products.results.length === 0;
  const table = loading ? (
    <Loading size="big" />
  ) : noResults ? (
    <NoResultsFound title="No hidden products found." />
  ) : (
    <>
      <AllPurchasesTable
        products={products.results}
        refreshBalance={refreshBalance}
        refreshTable={refreshTable}
        isTradeEnabled={false}
        isAuctionEnabled={false}
      />
      <ProductPaginator
        products={products}
        path={path}
        onChange={(location) => setSearch(location)}
      />
    </>
  );
  return <>{table}</>;
};
