import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Image, Segment } from "semantic-ui-react";

export default function HomePageProductCard({ region, value, image, alt }) {
  let history = useHistory();
  return (
    <Segment.Group className="product-card">
      <Segment basic>
        <Image className="product-card-image" src={image} alt={alt} />
      </Segment>
      <Segment>
        <b>{region} Unranked Accounts</b>
        <Button
          style={{ marginTop: "1em" }}
          color="twitter"
          fluid
          onClick={() => history.push(`/buy-account/?region=${value}`)}
        >
          Browse
        </Button>
      </Segment>
    </Segment.Group>
  );
}
