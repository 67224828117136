import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Icon, Pagination } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";

const useQuery = () => new URLSearchParams(useLocation().search);

export default function ProductPaginator({ products, path, onChange }) {
  const query = useQuery();
  const history = useHistory();

  const { isDarkMode } = useContext(DarkModeContext);

  const handlePageChange = (event, data) => {
    query.set("page", data.activePage);
    history.push({ pathname: path, search: query.toString() });
    onChange(`?${query.toString()}`);
  };

  const is_last_page = () => {
    return parseInt(query.get("page")) === products.totalPages;
  };

  const is_first_page = () =>
    !query.get("page") || parseInt(query.get("page")) === 1;

  return (
    <div className="product-pagination">
      <b>
        Showing {products.start} to {products.end} out of {products.count}
      </b>
      <Pagination
        inverted={isDarkMode}
        defaultActivePage={products.currentPage}
        totalPages={products.totalPages}
        onPageChange={handlePageChange}
        firstItem={{
          content: <Icon name="angle double left" />,
          icon: true,
          disabled: is_first_page(),
        }}
        lastItem={{
          content: <Icon name="angle double right" />,
          icon: true,
          disabled: is_last_page(),
        }}
        prevItem={{
          content: <Icon name="angle left" />,
          icon: true,
          disabled: is_first_page(),
        }}
        nextItem={{
          content: <Icon name="angle right" />,
          icon: true,
          disabled: is_last_page(),
        }}
        ellipsisItem={{
          content: <Icon name="ellipsis horizontal" />,
          icon: true,
          disabled: false,
        }}
      />
    </div>
  );
}
