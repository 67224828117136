import { useContext } from "react";
import { Header, Icon } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";

export default function NothingFound() {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <div className="no-search-results">
      <Header icon inverted={isDarkMode}>
        <Icon name="frown outline" />
        Nothing to show.
      </Header>
    </div>
  );
}
