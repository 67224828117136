import React from "react";
import { useHistory } from "react-router";
import { Button, Header, Icon, Segment } from "semantic-ui-react";

const NoCartItems = () => {
  const history = useHistory();

  return (
    <Segment placeholder className="no-cart-items-segment">
      <Header icon>
        <Icon name="cart" className="no-cart-items__icon" />
        <p className="no-cart-items__text">Your cart is empty.</p>
      </Header>
      <Button primary onClick={() => history.push("/buy-account/")}>
        Continue Browsing
      </Button>
    </Segment>
  );
};

export default NoCartItems;
