import React, { useEffect } from "react";
import { Container, Header } from "semantic-ui-react";
import { displayName, supportEmail, website } from "../constants";

export default function TosPage() {
  useEffect(() => (document.title = `${displayName} | Terms of Service`));
  return (
    <Container className="information-container">
      <Header>Terms of Service</Header>
      <p>
        This web page represents a legal document that serves as our Terms of
        Service and it governs the legal terms of our website, {website},
        sub-domains, and any associated web-based and mobile applications
        (collectively, “Website”), as owned and operated by {displayName}.
        <br />
        Capitalized terms, unless otherwise defined, have the meaning specified
        within the Definitions section below. This Terms of Service, along with
        our Privacy Policy, any mobile license agreement, and other posted
        guidelines within our Website, collectively “Legal Terms”, constitute
        the entire and only agreement between you and {displayName}, and
        supersede all other agreements, representations, warranties and
        understandings with respect to our Website and the subject matter
        contained herein. We may amend our Legal Terms at any time without
        specific notice to you. The latest copies of our Legal Terms will be
        posted on our Website, and you should review all Legal Terms prior to
        using our Website. After any revisions to our Legal Terms are posted,
        you agree to be bound to any such changes to them. Therefore, it is
        important for you to periodically review our Legal Terms to make sure
        you still agree to them.
        <br />
        By accessing this web site, you are agreeing to be bound by these
        Website Terms and Conditions of Use, all applicable laws and
        regulations, and agree that you are responsible for compliance with any
        applicable local laws. If you do not agree with any of these terms, you
        are prohibited from using or accessing this site. The materials
        contained in this Website are protected by applicable copyright and
        trademark law.
        <br />
        The last update to our Terms of Service was posted on January 6, 2016.
      </p>
      <p>
        <strong>Definitions</strong>
        <br />
        The terms “us” or “we” or “our” refers to {displayName}, the owner of
        the Website.
      </p>
      <p>
        A “Visitor” is someone who merely browses our Website, but has not
        registered as Member.
        <br />A “Member” is an individual that has registered with us to use our
        Service.
        <br />
        Our “Service” represents the collective functionality and features as
        offered through our Website to our Members.
        <br />A “User” is a collective identifier that refers to either a
        Visitor or a Member.
        <br />
        All text, information, graphics, audio, video, and data offered through
        our Website are collectively known as our “Content”.
      </p>
      <p>
        <strong>Use License</strong>
      </p>
      <p>
        Permission is granted to temporarily download one copy of the materials
        (information or software) on {displayName}’s Website for personal,
        non-commercial transitory viewing only. This is the grant of a license,
        not a transfer of title, and under this license you may not:
        <br />
        modify or copy the materials;
        <br />
        use the materials for any commercial purpose, or for any public display
        (commercial or non-commercial);
        <br /> attempt to decompile or reverse engineer any software contained
        on {displayName}’s web site;
        <br />
        remove any copyright or other proprietary notations from the materials;
        or
        <br />
        transfer the materials to another person or “mirror” the materials on
        any other server.
        <br />
        This license shall automatically terminate if you violate any of these
        restrictions and may be terminated by {displayName} at any time. Upon
        terminating your viewing of these materials or upon the termination of
        this license, you must destroy any downloaded materials in your
        possession whether in electronic or printed format.
      </p>
      <p>
        <strong>Restricted Uses</strong>
      </p>
      <p>
        Listing of offered products on the Website could be used only for lawful
        purposes by Users of the Website. You could not frame or utilize framing
        techniques to enclose any hallmark, logo, copyrighted image, or most
        proprietary details (consisting of images, text, page layout, or type)
        of {displayName} without express composed consent. You might not use any
        meta tags or any various other “unseen text” utilizing {displayName}’s
        name or trademarks without the express written consent of {displayName}.
        You agree not to offer or modify any content found on the Website
        consisting of, however not limited to, names of Users and Content, or to
        recreate, display, openly perform, distribute, or otherwise make use of
        the Material, in any way for any public function, in connection with
        services or products that are not those of {displayName}, in other way
        that is likely to trigger confusion among consumers, that disparages or
        challenges {displayName} or its licensors, that dilutes the strength of{" "}
        {displayName}’s or its licensor’s residential property, or that
        otherwise infringes {displayName}’s or its licensor’s copyright rights.
        You also agree to abstain from abusing any of the material that appears
        on the Site. The use of the Material on any other website or in a
        networked computer system environment for any purpose is prohibited. Any
        code that {displayName} develops to generate or show any Material of the
        pages making up the Website is likewise secured by {displayName}’s
        copyright, and you may not copy or adjust such code.
        <br />
        {displayName} has no duty to keep track of any products published,
        transferred, or connected to or with the Site. If you think that
        something on the Website breaches these Terms please contact our marked
        representative as set forth below.
        <br />
        If alerted by a User of any products which allegedly do not conform to
        these Terms, {displayName} could in its single discernment explore the
        allegation and figure out whether to take other actions or ask for the
        removal or get rid of the Content. {displayName} has no liability or
        duty to Individuals for efficiency or nonperformance of such activities.
      </p>
      <p>
        <strong>Electronic Communication</strong>
      </p>
      <p>
        You are connecting with us electronically when you go to the Website or
        send out emails to us. You consent to get interactions from us online.
        We will connect with you by email or by uploading notifications on the
        Site. You concur that all contracts notifications, disclosure, and
        various other communicates that we provide to you digitally please any
        legal requirements that such communications be in writing.
      </p>
      <p>
        <strong>Your Account</strong>
      </p>
      <p>
        If you utilize the Website, you are accountable for maintaining the
        confidentiality of your account and password and you accept
        responsibility for all activities that happen under your account and
        password. You also accept not to reveal any personally identifiable
        information, consisting of, however not limited to, first and last
        names, credentials, or various other details of a personal nature
        (“Personal Data”) from the Site. Your disclosure of any Personal Data on
        the website might result in the immediate termination of your account.{" "}
        {displayName} additionally reserves the right to refuse service,
        terminate accounts, and remove or edit Content at it’s sole discernment.
        <br />
        {displayName} does not guarantee the truthfulness or represent,
        precision, or dependability of Content on the site, consisting of
        Personal Data. Each Individual is accountable for upgrading and changing
        any pertinent account info when essential to preserve the truthfulness,
        precision, or reliability of the details.
      </p>
      <p>
        <strong>Reviews, Comments, and Other Material</strong>
      </p>
      <p>
        Registered Users of the Website might post evaluations and remarks of a
        product and services purchased by means of the Website, so long as the
        Material is not unlawful, profane, threatening, defamatory, an invasive
        of privacy, infringing of intellectual property rights, or otherwise
        injurious to third parties or objectionable and does not include or
        include industrial solicitation, mass mailings, or any type of “spam.”
        You may not use another User’s account to impersonate a User or entity,
        or otherwise deceived as to the origin of the opinions. {displayName}{" "}
        books the right (however is not bound) to eliminate or modify such
        Material, but does not regularly examine posted Material.
        <br /> If you post an evaluation or send comments, and unless{" "}
        {displayName} suggests otherwise, you grant {displayName} a
        nonexclusive, royalty-free, permanent, irrevocable, and completely
        sublicensable right to utilize, recreate, modify, adjust, release,
        equate, create derivative works from, distribute, and screen such
        content throughout the world, in any media. You grant {displayName} and
        sublicenses the right to utilize your name in connection with such
        Material, if they choose. You represent and require that You own or
        otherwise control all the rights to the content that You post; that the
        content is accurate; that use of the content You supply does not violate
        this policy and will not trigger injury to anyone or entity; which You
        will indemnify {displayName} for all claims resulting from Content You
        supply. {displayName} has the right but not the commitment to edit and
        keep track of or eliminate any task or Material. {displayName} takes no
        duty and assumes no liability for any content published by You or any
        3rd party.
      </p>
      <p>
        <strong>Legal Compliance</strong>
        <br />
        You agree to comply with all applicable domestic and international laws,
        statutes, ordinances, and regulations regarding your use of our Website.{" "}
        {displayName} reserves the right to investigate complaints or reported
        violations of our Legal Terms and to take any action we deem
        appropriate, including but not limited to canceling your Member account,
        reporting any suspected unlawful activity to law enforcement officials,
        regulators, or other third parties and disclosing any information
        necessary or appropriate to such persons or entities relating to your
        profile, email addresses, usage history, posted materials, IP addresses
        and traffic information, as allowed under our Privacy Policy.
      </p>
      <p>
        <strong>Intellectual Property</strong>
      </p>
      <p>
        Our Website may contain our service marks or trademarks as well as those
        of our affiliates or other companies, in the form of words, graphics,
        and logos. Your use of our Website does not constitute any right or
        license for you to use such service marks/trademarks, without the prior
        written permission of the corresponding service mark/trademark owner.
        Our Website is also protected under international copyright laws. The
        copying, redistribution, use or publication by you of any portion of our
        Website is strictly prohibited. Your use of our Website does not grant
        you ownership rights of any kind in our Website.
      </p>
      <p>
        <strong>Revisions and Errata</strong>
      </p>
      <p>
        The materials appearing on {displayName}’s Website could include
        technical, typographical, or photographic errors. {displayName} does not
        warrant that any of the materials on its Website are accurate, complete,
        or current. {displayName} may make changes to the materials contained on
        its Website at any time without notice. {displayName} does not, however,
        make any commitment to update the materials.
      </p>
      <p>
        <strong>Disclaimer</strong>
      </p>
      <p>
        The materials on {displayName}’s Website are provided “as is”{" "}
        {displayName} makes no warranties, expressed or implied, and hereby
        disclaims and negates all other warranties, including without
        limitation, implied warranties or conditions of merchantability, fitness
        for a particular purpose, or non-infringement of intellectual property
        or other violation of rights. Further, {displayName} does not warrant or
        make any representations concerning the accuracy, likely results, or
        reliability of the use of the materials on its Internet Website or
        otherwise relating to such materials or on any sites linked to this
        site. The Website serves as a venue for Individuals to purchase distinct
        service or products. Neither {displayName} nor the Website has control
        over the quality or fitness for a particular function of a product.{" "}
        {displayName} likewise has no control over the accuracy, reliability,
        completeness, or timeliness of the User-submitted details and makes no
        representations or warranties about any info on the Site.
        <br />
        THE WEBSITE AND ALL DETAILS, CONTENT, MATERIALS, PRODUCTS (INCLUDING
        SOFTWARE APPLICATION) AND SERVICES CONSISTED OF ON OR OTHERWISE MADE
        AVAILABLE TO YOU THROUGH THIS WEBSITE ARE PROVIDED BY {displayName} ON
        AN “AS IS” AND “AS AVAILABLE” BASIS, UNLESS OTHERWISE SPECIFIED IN
        WRITING. {displayName} MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY
        KIND, EXPRESS OR IMPLIED, ABOUT THE OPERATION OF THIS Website OR THE
        INFO, MATERIAL, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR SERVICES
        CONSISTED OF ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THIS SITE,
        UNLESS OTHERWISE POINTED OUT IN WRITING. YOU EXPRESSLY AGREE THAT YOUR
        USE OF THIS WEBSITE IS AT YOUR OWN RISK.
        <br />
        TO THE COMPLETE EXTENT PERMISSIBLE BY APPLICABLE LAW, {displayName}{" "}
        DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED
        TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND PHYSICAL FITNESS FOR A
        PARTICULAR PURPOSE. {displayName} DOES NOT WARRANT THAT THIS WEBSITE;
        DETAILS, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE APPLICATION)
        OR SERVICES CONSISTED OF ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH
        THE SITE; ITS SERVERS; OR EMAIL SENT FROM {displayName} ARE WITHOUT
        VIRUSES OR OTHER HARMFUL ELEMENTS. {displayName} WILL NOT BE LIABLE FOR
        ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE Website OR FROM ANY
        DETAILS, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE APPLICATION)
        OR SERVICES CONSISTED OF ON OR OTHERWISE MADE AVAILABLE TO YOU WITH THIS
        SITE, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL,
        PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE POINTED OUT IN
        WRITING. UNDER NO SCENARIO SHALL {displayName}’S LIABILITY DEVELOPING
        FROM OR IN CONNECTION WITH THE WEBSITE OR YOUR USE OF THE WEBSITE,
        DESPITE THE REASON FOR ACTION (WHETHER IN AGREEMENT, TORT, BREACH OF
        SERVICE WARRANTY OR OTHERWISE), GO BEYOND $100.
      </p>
      <p>
        <strong>Links to Other Websites</strong>
      </p>
      <p>
        Our Website may contain links to third party websites. These links are
        provided solely as a convenience to you. By linking to these websites,
        we do not create or have an affiliation with, or sponsor such third
        party websites. The inclusion of links within our Website does not
        constitute any endorsement, guarantee, warranty, or recommendation of
        such third party websites. {displayName} has no control over the legal
        documents and privacy practices of third party websites; as such, you
        access any such third party websites at your own risk.
      </p>
      <p>
        <strong>Refund Policy</strong>
      </p>
      <p>
        All purchases are final and can not be refunded.
        <br />
        However, we allow a refund if you purchase by mistake and you haven’t
        registered the email, opened any loot crate, played any game and spend
        any kind of currency on the account. If you ask for a refund and the
        account is exactly as it was delivered to you, you’ll have 3 days to ask
        us for a refund.
      </p>
      <p>
        In the case the account is banned, there is 3 months warranty since the
        date of purchase. To be elegible for the warranty, you shouldn’t have
        done anything on the account to trigger the ban ( exhibit in-game toxic
        behaviour, cheating, griefing, intentional feeding and similar actions
        that can lead to a suspension of the account.
        <br />
        The warranty only applies if the ban it’s related to us, for example the
        leveling process or selling the account to other players.
      </p>
      <p>
        <strong>Site Terms of Service Modifications</strong>
      </p>
      <p>
        {displayName} may revise these Terms of Service for its Website at any
        time without notice. By using this Website you are agreeing to be bound
        by the then current version of these Terms and Conditions of Use.
      </p>
      <p>
        <strong>Governing Law</strong>
      </p>
      <p>
        Any claim relating to {displayName}’s Website shall be governed by the
        laws of Spain without regard to its conflict of law provisions, and You
        consent to exclusive jurisdiction and venue in such courts.
      </p>
      <p>
        <strong>Indemnity</strong>
      </p>
      <p>
        You accept defend, indemnify, and hold safe {displayName}, its
        affiliates, and their corresponding officers, directors, agents and
        workers, from and against any claims, actions or demands, including
        without limitation affordable legal, accounting, and other provider
        charges, affirming or resulting from (i) any Content of most material
        You offer to the Site, (ii) Your use of any Content, or (iii) Your
        breach of the terms of these Terms. {displayName} will provide notice to
        You promptly of any such claim, match, or case.
      </p>
      <p>
        <strong>General Terms</strong>
      </p>
      <p>
        Our Legal Terms shall be treated as though it were executed and
        performed in Barcelona, Spain and shall be governed by and construed in
        accordance with the laws of Barcelona, Spain without regard to conflict
        of law principles. In addition, you agree to submit to the personal
        jurisdiction and venue of such courts. Any cause of action by you with
        respect to our Website, must be instituted within one (1) year after the
        cause of action arose or be forever waived and barred. Should any part
        of our Legal Terms be held invalid or unenforceable, that portion shall
        be construed consistent with applicable law and the remaining portions
        shall remain in full force and effect. To the extent that any Content in
        our Website conflicts or is inconsistent with our Legal Terms, our Legal
        Terms shall take precedence. Our failure to enforce any provision of our
        Legal Terms shall not be deemed a waiver of such provision nor of the
        right to enforce such provision. The rights of {displayName} under our
        Legal Terms shall survive the termination of our Legal Terms.
      </p>
      <p>
        Additional Terms and Conditions; EULAs
        <br />
        When you use G2A Pay services provided by G2A.COM Limited (hereinafter
        referred to as the “G2A Pay services provider”) to make a purchase on
        our website, responsibility over your purchase will first be transferred
        to G2A.COM Limited before it is delivered to you. G2A Pay services
        provider assumes primary responsibility, with our assistance, for
        payment and payment related customer support. The terms between G2A Pay
        services provider and customers who utilize services of G2A Pay are
        governed by separate agreements and are not subject to the Terms on this
        website.
        <br />
        With respect to customers making purchases through G2A Pay services
        provider checkout, (i) the Privacy Policy of G2A Pay services provider
        shall apply to all payments and should be reviewed before making any
        purchase, and (ii) the G2A Pay services provider Refund Policy shall
        apply to all payments unless notice is expressly provided by the
        relevant supplier to buyers in advance. In addition the purchase of
        certain products may also require shoppers to agree to one or more
        End-User License Agreements (or “EULAs”) that may include additional
        terms set by the product supplier rather than by Us or G2A Pay services
        provider. You will be bound by any EULA that you agree to.
        <br />
        We and/or entities that sell products on our website by using G2A Pay
        services are primarily responsible for warranty, maintenance, technical
        or product support services for those Products. We and/or entities that
        sell products on our website are primarily responsible to users for any
        liabilities related to fulfillment of orders, and EULAs entered into by
        the End-User Customer. G2A Pay services provider is primarily
        responsible for facilitating your payment.
        <br />
        You are responsible for any fees, taxes or other costs associated with
        the purchase and delivery of your items resulting from charges imposed
        by your relationship with payment services providers or the duties and
        taxes imposed by your local customs officials or other regulatory body.
        <br />
        For customer service inquiries or disputes, You may contact us by email
        at {supportEmail}.
        <br />
        Questions related to payments made through G2A Pay services provider
        payment should be addressed to support@g2a.com.
        <br />
        Where possible, we will work with You and/or any user selling on our
        website, to resolve any disputes arising from your purchase.
      </p>
      <p>
        Additional Terms and Conditions; EULAs
        <br />
        When you use G2A Pay services provided by G2A.COM Limited (hereinafter
        referred to as the “G2A Pay services provider”) to make a purchase on
        our website, responsibility over your purchase will first be transferred
        to G2A.COM Limited before it is delivered to you. G2A.COM is becoming
        Merchant of Record over your purchase. G2A Pay services provider assumes
        primary responsibility, with our assistance, for payment and payment
        related customer support. The terms between G2A Pay services provider
        and customers who utilize services of G2A Pay are governed by separate
        agreements which can be found under the link
        https://pay.g2a.com/terms-and-conditions and are not subject to the
        Terms on this website.
        <br />
        In order to proceed the payment transaction, you temporary entrusts the
        G2A.COM with subject of the transaction, and G2A.COM takes
        responsibility for the product and for the transaction processing.
        <br />
        With respect to customers making purchases through G2A Pay services
        provider checkout, (i) the Privacy Policy of G2A Pay services provider
        shall apply to all payments and should be reviewed before making any
        purchase, and (ii) the G2A Pay services provider Refund Policy shall
        apply to all payments unless notice is expressly provided by the
        relevant supplier to buyers in advance. In addition the purchase of
        certain products may also require shoppers to agree to one or more
        End-User License Agreements (or “EULAs”) that may include additional
        terms set by the product supplier rather than by Us or G2A Pay services
        provider. You will be bound by any EULA that you agree to.
        <br />
        You are responsible for any fees, taxes or other costs associated with
        the purchase and delivery of your items resulting from charges imposed
        by your relationship with payment services providers or the duties and
        taxes imposed by your local customs officials or other regulatory body.
      </p>
      <p>
        For customer service inquiries or disputes, You may contact us by email
        at {supportEmail}
        <br />
        Questions related to payments made through G2A Pay services provider
        payment should be addressed to support@g2a.com.
        <br />
        Where possible, we will work with you and/or any user selling on our
        website, to resolve any disputes arising from your purchase.
      </p>
    </Container>
  );
}
