import Cookies from "js-cookie";
import { useContext, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { Button, Form, Header, Message, Segment } from "semantic-ui-react";
import { captchaSiteKey } from "../constants";
import CartContext from "../contexts/CartContext";
import DarkModeContext from "../contexts/DarkModeContext";

function RegisterForm() {
  const [email, setEmail] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState();
  const captchaRef = useRef();

  const { refreshCart } = useContext(CartContext);

  const history = useHistory();
  const { isDarkMode } = useContext(DarkModeContext);

  const registerStatus = async (queue_id) => {
    // Return whether to break or keep polling
    try {
      const response = await fetch(`/api/register/status/${queue_id}/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      });
      if (response.ok) {
        const data = await response.json();
        if (data.user) {
          refreshCart();
          history.push({
            pathname: "/my-account/profile/your-purchases/",
            search: "?page=1&limit=5",
          });
          return false;
        }
        setErrors(data);
        return true;
      }
      const data = await response.json();
      captchaRef.current?.reset();
      setCaptcha();
      setErrors(data);
      setLoading(false);
      return false;
    } catch (reason) {
      console.log(reason);
      setLoading(false);
    }
    return false;
  };

  const register = async () => {
    try {
      if (password1 !== password2) {
        setErrors({ password2: "Passwords do not match." });
        return;
      }

      setLoading(true);
      setSuccess(false);
      const response = await fetch("/api/register/", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({
          email,
          password1,
          password2,
          captcha: captcha || "",
        }),
      });
      if (response.status === 429) {
        history.push("/429/");
      }
      if (response.ok) {
        const data = await response.json();
        const queueId = data.queueId;
        if (!queueId) {
          setLoading(false);
          return;
        }
        while (true) {
          if (!(await registerStatus(queueId))) return;
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
      setSuccess(false);
      setLoading(false);
      const data = await response.json();
      setErrors(data);
      captchaRef.current?.reset();
      setCaptcha();
    } catch (reason) {
      console.log(reason);
      setLoading(false);
    }
  };

  return (
    <>
      <Header as="h2" color="teal" textAlign="center">
        Register a new account
      </Header>
      <Segment basic>
        <Form
          inverted={isDarkMode}
          size="large"
          success={success}
          error={
            errors.nonFieldErrors !== undefined || errors.detail !== undefined
          }
          onSubmit={register}
        >
          <Form.Input
            icon="user"
            iconPosition="left"
            label="Email"
            placeholder="Email"
            type="email"
            value={email}
            onChange={(e, { value }) => setEmail(value)}
            error={errors.email}
            required
          />
          <Form.Input
            icon="lock"
            iconPosition="left"
            label="Password"
            placeholder="Password"
            type="password"
            value={password1}
            onChange={(e, { value }) => setPassword1(value)}
            error={errors.password1}
            required
            minLength="8"
          />
          <Form.Input
            icon="lock"
            iconPosition="left"
            label="Confirm Password"
            placeholder="Confirm Password"
            type="password"
            value={password2}
            onChange={(e, { value }) => setPassword2(value)}
            error={errors.password2}
            required
          />

          <Message
            success
            header="Registration Completed"
            content="You have succesfully registered. A confirmation email has been sent to you."
          />
          <Message
            error
            header="Registration Failed"
            content={errors.nonFieldErrors || errors.detail}
          />

          {captchaSiteKey && (
            <ReCAPTCHA
              className="recaptcha"
              ref={captchaRef}
              sitekey={captchaSiteKey}
              onChange={(value) => setCaptcha(value)}
            />
          )}
          <Button
            type="submit"
            color="teal"
            size="large"
            disabled={loading || (captchaSiteKey && !captcha)}
            loading={loading}
          >
            Register
          </Button>
        </Form>
      </Segment>
    </>
  );
}

export default RegisterForm;
