import { useContext } from "react";
import { Table, Message, Icon } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";

export const generateFilesLimit = 3; // one file for each content type

export default function ExportTable({ files, setFiles }) {
  const { isDarkMode } = useContext(DarkModeContext);
  const handleDownload = (el) => () => {
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
  };
  const handleRemove = (file) => () => {
    setFiles((files) => files.filter((f) => f.type !== file.type));
  };
  return (
    <>
      <Message
        attached
        color={isDarkMode ? "black" : "grey"}
        className="export-data-table-message"
        header={`Generated Files (${files.length}/${generateFilesLimit})`}
        content="Download or remove the generated files."
      />
      <Table inverted={isDarkMode} className="export-data-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Content</Table.HeaderCell>
            <Table.HeaderCell>Format</Table.HeaderCell>
            <Table.HeaderCell>Rows</Table.HeaderCell>
            <Table.HeaderCell>Size</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {files.length ? (
            files.map((file, idx) => {
              return (
                <Table.Row key={idx}>
                  <Table.Cell>{file.date}</Table.Cell>
                  <Table.Cell>{file.type.toUpperCase()}</Table.Cell>
                  <Table.Cell>{file.format.toUpperCase()}</Table.Cell>
                  <Table.Cell>{file.rows}</Table.Cell>
                  <Table.Cell>
                    {(parseInt(file.size) / 1000).toFixed(2)} Kb
                  </Table.Cell>
                  <Table.Cell>
                    <Icon
                      link
                      name="download"
                      onClick={handleDownload(file.element)}
                    />
                    <Icon link name="remove" onClick={handleRemove(file)} />
                  </Table.Cell>
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan="6" textAlign="center">
                <Icon name="file excel" />
                No files generated.
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}
