import { useContext, useState } from "react";
import { Table } from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";
import { AuctionModal } from "../Modal/AuctionModal";
import { TradeModal } from "../TradeModal";
import { AllPurchasesTableItem } from "./AllPurchasesTableItem";
import { ProductHideModal } from "../Modal/ProductHideModal";

export const AllPurchasesTable = ({
  products,
  refreshBalance,
  refreshTable,
  isTradeEnabled,
  isAuctionEnabled,
}) => {
  const { isDarkMode } = useContext(DarkModeContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuctionModalOpen, setIsAuctionModalOpen] = useState(false);
  const [isHideModalOpen, setIsHideModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  return (
    <div className="your-purchases-wrapper">
      <Table inverted={isDarkMode} singleLine stackable={false}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Purchased Date</Table.HeaderCell>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>Password</Table.HeaderCell>
            <Table.HeaderCell>Region</Table.HeaderCell>
            <Table.HeaderCell>Level</Table.HeaderCell>
            <Table.HeaderCell>BE</Table.HeaderCell>
            <Table.HeaderCell>OE</Table.HeaderCell>
            <Table.HeaderCell>Ranked W/L</Table.HeaderCell>
            <Table.HeaderCell>Skins</Table.HeaderCell>
            <Table.HeaderCell>Tags</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {products.map((product, index) => (
            <AllPurchasesTableItem
              key={index}
              product={product}
              index={index}
              setIsModalOpen={setIsModalOpen}
              setIsAuctionModalOpen={setIsAuctionModalOpen}
              setIsHideModalOpen={setIsHideModalOpen}
              setSelectedProduct={setSelectedProduct}
              isTradeEnabled={isTradeEnabled}
              isAuctionEnabled={isAuctionEnabled}
            />
          ))}
        </Table.Body>
      </Table>
      {isTradeEnabled && isModalOpen && (
        <TradeModal
          setOpen={setIsModalOpen}
          product={selectedProduct}
          refreshBalance={refreshBalance}
          refreshTable={refreshTable}
        />
      )}
      {isAuctionEnabled && isAuctionModalOpen && (
        <AuctionModal
          setOpen={setIsAuctionModalOpen}
          product={selectedProduct}
          refreshBalance={refreshBalance}
          refreshTable={refreshTable}
        />
      )}
      {isHideModalOpen && (
        <ProductHideModal
          setOpen={setIsHideModalOpen}
          product={selectedProduct}
          refreshTable={refreshTable}
        />
      )}
    </div>
  );
};
