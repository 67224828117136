import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Header, List, Segment } from "semantic-ui-react";
import {
  disableElitepvpersBacklink,
  displayName,
  ownerName,
  ownerLocation,
  supportEmail,
  registeredLocation,
} from "../constants";
export default function Footer({ isSmurfSKins }) {
  let history = useHistory();
  return (
    <Segment inverted className="footer-wrapper">
      <Grid inverted divided stackable className="footer">
        <Grid.Row>
          <Grid.Column width={2}>
            <Header inverted as="h4" content="About" />
            <List link inverted>
              <List.Item as="a" onClick={() => history.push("/buy-account/")}>
                Buy Account
              </List.Item>
              <List.Item as="a" onClick={() => history.push("/faq/")}>
                Faqs
              </List.Item>
              <List.Item as="a" onClick={() => history.push("/tos/")}>
                Terms of Service
              </List.Item>
              <List.Item
                as="a"
                onClick={() => history.push("/privacy-policy/")}
              >
                Privacy Policy
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={6}>
            <Header as="h4" inverted>
              You can reach us anytime at{" "}
              <a href={`mailto:${supportEmail}`}>{supportEmail}.</a>
            </Header>
            <p
              style={{
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              {`${displayName.toUpperCase()} is a trading name of ${ownerName}`} 
              <br />
              {`Head office in ${ownerLocation}.`}
              <br />
              Registered in {registeredLocation}.
            </p>
          </Grid.Column>
          <Grid.Column width={4} textAlign="center">
            <Header as="h4" inverted>
              Over 15000 sales on G2G
            </Header>
            <a href="https://www.g2g.com/Undernoxo">
              <img src="/static/images/g2g.png" alt="g2g" />
            </a>
          </Grid.Column>
          {disableElitepvpersBacklink ? null : (
            <Grid.Column width={4} textAlign="center">
              <Header as="h4" inverted>
                You can find us on elitepvpers
              </Header>
              <a href="https://www.elitepvpers.com/forum/league-legends-trading/5081443-smurfskins-lol-choose-your-skins-all-regions-starting-2-5-up-200k.html#post39389368">
                <img
                  src="/static/images/elitepvpers.png"
                  alt=""
                  title="e*pvp BackLink"
                />
              </a>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </Segment>
  );
}
