import React, { useContext, useEffect, useState } from "react";
import { Accordion, Container, Header, Icon, Segment } from "semantic-ui-react";
import { displayName } from "../constants";
import DarkModeContext from "../contexts/DarkModeContext";

const faqs = require("../json/faq.json");

export default function FaqPage() {
  const [active, setActive] = useState(-1);

  const { isDarkMode } = useContext(DarkModeContext);

  useEffect(() => (document.title = `${displayName} | FAQ`));

  return (
    <div>
      <Container className="information-container">
        <Header inverted={isDarkMode}>FAQ</Header>
        {faqs.map((faq, index) => (
          <Segment inverted={isDarkMode} key={index}>
            <Accordion inverted={isDarkMode}>
              <Accordion.Title
                onClick={() => {
                  active === index ? setActive(-1) : setActive(index);
                }}
              >
                {faq.title}
                <Icon name="caret down" />
              </Accordion.Title>
              <Accordion.Content active={index === active}>
                {faq.content}
              </Accordion.Content>
            </Accordion>
          </Segment>
        ))}
      </Container>
    </div>
  );
}
