import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { Button, Container, Message } from "semantic-ui-react";
import CartContext from "../contexts/CartContext";

export default function PurchaseSuccessful() {
  const history = useHistory();

  const { refreshCart } = useContext(CartContext);

  useEffect(() => refreshCart(), [refreshCart]);

  return (
    <Container style={{ padding: "1em" }}>
      <Message positive>
        Your purchase has been completed successfully. The items will be
        delivered in your purchases shortly.
      </Message>
      <Button
        onClick={() =>
          history.push({
            pathname: "/my-account/profile/your-purchases/",
            search: "?page=1&limit=5",
          })
        }
      >
        Check my purchases
      </Button>
    </Container>
  );
}
