import React, { useContext } from "react";
import { Header, Icon } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";
import { Link } from "react-router-dom";

export default function NoSearchResults({ isBuyAccountPage = false }) {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <div className="no-search-results">
      <Header inverted={isDarkMode} icon>
        <Icon name="search" />
        No search results found.
      </Header>
      {isBuyAccountPage && (
        <Header inverted={isDarkMode} icon>
          Explore more accounts on the
          <Link to="/marketplace"> Marketplace</Link>.
        </Header>
      )}
    </div>
  );
}
