import Cookies from "js-cookie";
import { useContext, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, Header, Image, Menu } from "semantic-ui-react";
import CartContext from "../contexts/CartContext";
import DarkModeContext from "../contexts/DarkModeContext";

export default function UserProfileSidebar({
  email,
  balance,
  balanceTransferAllowed,
}) {
  const [loggingOut, setLoggingOut] = useState(false);
  const history = useRef(useHistory());
  const { refreshCart } = useContext(CartContext);

  const isYourTransactions = useRouteMatch(
    "/my-account/profile/your-transactions",
  );
  const isChangePassword = useRouteMatch("/my-account/profile/change-password");
  const isExportData = useRouteMatch("/my-account/profile/export-data");
  const isRedeemCoupon = useRouteMatch("/my-account/profile/redeem-coupon");
  const isLoadBalance = useRouteMatch("/my-account/profile/load-balance");
  const isTransferBalance = useRouteMatch(
    "/my-account/profile/transfer-balance",
  );
  const isTransferBalanceCrossSite = useRouteMatch(
    "/my-account/profile/transfer-balance-cross-site",
  );
  const isYourPayments = useRouteMatch("/my-account/profile/your-payments");

  const getActive = () => {
    if (isChangePassword) return "change-password";
    if (isExportData) return "export-data";
    if (isYourTransactions) return "your-transactions";
    if (isRedeemCoupon) return "redeem-coupon";
    if (isLoadBalance) return "load-balance";
    if (isTransferBalance && balanceTransferAllowed) return "transfer-balance";
    if (isTransferBalanceCrossSite) return "transfer-balance-cross-site";
    if (isYourPayments) return "your-payments";
    return "your-purchase";
  };

  const logout = async () => {
    try {
      setLoggingOut(true);
      const response = await fetch("/api/auth/logout/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      });
      if (response.status === 429) {
        history.current.push("/429/");
      }
      if (response.ok) {
        history.current.push("/my-account/");
        refreshCart();
      }
    } catch (error) {
      console.error();
    } finally {
      setLoggingOut(false);
    }
  };

  const { isDarkMode } = useContext(DarkModeContext);

  const active = getActive();

  return (
    <Menu
      pointing
      inverted={isDarkMode}
      secondary
      vertical
      style={{ width: "max-content" }}
    >
      <Menu.Item>
        <Image className="user-profile-image" src="/static/images/jenny.jpg" />
        <Header inverted={isDarkMode}>
          {email}
          <Header.Subheader>Customer Account</Header.Subheader>
          <Header.Subheader>
            Balance:{" "}
            {balance.toLocaleString(undefined, {
              style: "currency",
              currency: "EUR",
            })}
          </Header.Subheader>
        </Header>
      </Menu.Item>
      <Menu.Item
        name="Redeem Coupon"
        active={active === "redeem-coupon"}
        onClick={() =>
          history.current.push("/my-account/profile/redeem-coupon/")
        }
      />
      <Menu.Item
        name="Load Balance"
        active={active === "load-balance"}
        onClick={() =>
          history.current.push("/my-account/profile/load-balance/")
        }
      />
      {balanceTransferAllowed && (
        <>
          <Menu.Item
            name="Transfer Balance"
            active={active === "transfer-balance"}
            onClick={() =>
              history.current.push("/my-account/profile/transfer-balance/")
            }
          />
        </>
      )}
      <Menu.Item
        active={active === "transfer-balance-cross-site"}
        onClick={() =>
          history.current.push(
            "/my-account/profile/transfer-balance-cross-site/",
          )
        }
      >
        Transfer Balance (Cross Site)
      </Menu.Item>
      <Menu.Item
        name="Your Purchases"
        active={active === "your-purchase"}
        onClick={() =>
          history.current.push({
            pathname: "/my-account/profile/your-purchases/",
            search: "?page=1&limit=5",
          })
        }
      />
      <Menu.Item
        name="Your Transactions"
        active={active === "your-transactions"}
        onClick={() =>
          history.current.push({
            pathname: "/my-account/profile/your-transactions/",
            search: "?page=1&limit=5",
          })
        }
      />
      <Menu.Item
        name="Your Payments"
        active={active === "your-payments"}
        onClick={() =>
          history.current.push({
            pathname: "/my-account/profile/your-payments/",
            search: "?page=1&limit=5",
          })
        }
      />
      <Menu.Item
        name="Change Password"
        active={active === "change-password"}
        onClick={() =>
          history.current.push("/my-account/profile/change-password/")
        }
      />
      <Menu.Item
        name="Export Data"
        active={active === "export-data"}
        onClick={() => history.current.push("/my-account/profile/export-data/")}
      />
      <Menu.Item>
        <Button
          size="small"
          content="Logout"
          color="red"
          basic
          onClick={logout}
          loading={loggingOut}
        />
      </Menu.Item>
    </Menu>
  );
}
