import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Divider, Header } from "semantic-ui-react";
import { displayName } from "../constants";
import DarkModeContext from "../contexts/DarkModeContext";
import HomePageProductCard from "./HomePageProductCard";
import SkinShardSearch from "./SkinShardSearch";

export default function LolAccShopHomePage() {
  const history = useHistory();
  const { isDarkMode } = useContext(DarkModeContext);

  useEffect(
    () => (document.title = `${displayName} | Best place to buy LoL accounts`),
  );
  return (
    <>
      <img
        className="homepage-banner"
        src="/static/images/buy-lol-account.jpg"
        alt="banner"
      />
      <div className="homepage-navigation">
        <Header
          inverted={isDarkMode}
          className="homepage-header"
          content="Search accounts with your favorite skin"
          textAlign="center"
        />
        <SkinShardSearch
          size="huge"
          resultCount={7}
          onChange={(value) =>
            history.push(
              `/buy-account/?skin-shard-option=specific&skin-shard=${value[0]}`,
            )
          }
        />
        <Divider horizontal>Or</Divider>
        <Header
          className="homepage-header"
          content="Browse accounts by region"
          textAlign="center"
        />
        <div className="homepage-product-card-group">
          <HomePageProductCard
            region={"EUW"}
            value={"EUW"}
            image="/static/images/euw.png"
            alt="region-euw-image"
          />
          <HomePageProductCard
            region={"EUNE"}
            value={"EUNE"}
            image="/static/images/eune.png"
            alt="region-eune-image"
          />
          <HomePageProductCard
            region={"NA"}
            value={"NA"}
            image="/static/images/na.png"
            alt="region-na-image"
          />
          <HomePageProductCard
            region={"OCE"}
            value={"OC1"}
            image="/static/images/oce.png"
            alt="region-oce-image"
          />
        </div>
      </div>
    </>
  );
}
