import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Icon, Message } from "semantic-ui-react";

const Error = ({ errors }) => {
  useEffect(() => {}, [errors]);

  const messageStyle = {
    wordWrap: "break-word",
  };

  if (!Object.keys(errors).length) return <></>;
  if (errors.code === "S400") {
    return (
      <Message error style={messageStyle}>
        {Object.values(errors.error)
          .flat()
          .map((error, index) => (
            <span key={index}>
              <Icon name="info circle" />
              {error}
            </span>
          ))}
      </Message>
    );
  }
  return (
    <>
      <Message error style={messageStyle}>
        <Icon name="warning circle" />
        {errors.error}
      </Message>
      {errors.error === "Insufficient balance for bidding" && (
        <Message info style={messageStyle}>
          <Icon name="info circle" />
          <Link to="/my-account/profile/load-balance/">Click here</Link> to load
          balance.
        </Message>
      )}
    </>
  );
};

export default Error;
