import { useContext } from "react";
import { Dropdown } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";

export default function CustomDropDown(props) {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <div className={`custom-dropdown${isDarkMode ? " dark" : ""}`}>
      <Dropdown fluid {...props} />
    </div>
  );
}
