import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { Stepper } from "react-form-stepper";
import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Loader,
  Message,
  Modal,
} from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";
import SkinContext from "../contexts/SkinContext";
import { getThumbnail, getTradeLabelTags } from "../utils";
import ProductCard from "./ProductCard";

const TradeStepper = ({ steps, currentStep }) => {
  return (
    <Stepper
      steps={steps}
      disabled={true}
      activeStep={currentStep}
      styleConfig={{
        activeBgColor: "#2185D0",
        completedBgColor: "#2185D0",
        circleFontSize: "0",
        size: "1em",
        labelFontSize: "0em",
      }}
    />
  );
};

const ConfirmSection = ({
  isDarkMode,
  accountPassword,
  setAccountPassword,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      <Header inverted={isDarkMode}>
        Do you want to trade your account for balance?
      </Header>
      <Form inverted={isDarkMode}>
        <Form.Field>
          <label>Enter the current account password</label>
          <Input
            placeholder={accountPassword}
            icon={
              <Icon
                name={showPassword ? "eye" : "eye slash"}
                link
                onClick={() => setShowPassword(!showPassword)}
              />
            }
            type={showPassword ? "text" : "password"}
            onChange={(e, { value }) => setAccountPassword(value)}
            value={accountPassword}
          />
        </Form.Field>
      </Form>
      <Message info size="small">
        <Message.Header>Requirements for account trade:</Message.Header>
        <Message.List>
          <Message.Item>Unverified email</Message.Item>
          <Message.Item>No ban or restrictions</Message.Item>
          <Message.Item>Minimum honor level 2</Message.Item>
          <Message.Item>
            For silver/bronze: at least 20 games played
          </Message.Item>
        </Message.List>
      </Message>
    </>
  );
};

const ValuateSection = ({ isDarkMode, accountValue }) => {
  return (
    <>
      <Header inverted={isDarkMode}>
        For this account, you'll receive €{accountValue} of Account Balance.{" "}
        <br />
        Do you want to proceed?
      </Header>
      <Message info>
        <Message.List>
          <Message.Item> This will change the account password.</Message.Item>
          <Message.Item>
            You will receive the balance after 24 hours.
          </Message.Item>
        </Message.List>
      </Message>
    </>
  );
};

const CompleteSection = ({ isDarkMode }) => {
  return (
    <>
      <Header inverted={isDarkMode}>
        Your account has been traded for balance.
      </Header>
    </>
  );
};

export const TradeModal = ({
  setOpen,
  product,
  refreshBalance,
  refreshTable,
}) => {
  const { isDarkMode } = useContext(DarkModeContext);
  const steps = [
    { label: "Confirm" },
    { label: "Valuate" },
    { label: "Complete" },
  ];
  const [currentStep, setCurrentStep] = useState(0);
  const [accountPassword, setAccountPassword] = useState(product.password);
  const [accountValue, setAccountValue] = useState(0);
  const [success, setSuccess] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [label_tags] = getTradeLabelTags(product);
  product["label_tags"] = label_tags;
  const { skinShards } = useContext(SkinContext);
  const productSkins = [
    ...product.skins,
    ...product.permanentSkins,
    ...product.ownedSkins,
  ];
  const [thumbnail] = getThumbnail(skinShards, productSkins);
  product["thumbnail"] = thumbnail;

  const [currentProduct] = useState(product);
  const getSection = (currentStep) => {
    switch (currentStep) {
      case 0:
        return (
          <ConfirmSection
            isDarkMode={isDarkMode}
            product={currentProduct}
            accountPassword={accountPassword}
            setAccountPassword={setAccountPassword}
          />
        );
      case 1:
        return (
          <ValuateSection isDarkMode={isDarkMode} accountValue={accountValue} />
        );
      case 2:
        return <CompleteSection isDarkMode={isDarkMode} />;
      default:
        return null;
    }
  };

  const valuateAccount = async () => {
    setSuccess(false);
    setErrors({});

    try {
      setLoading(true);
      const response = await fetch("/api/products/purchased/valuate/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({
          username: currentProduct.username,
          password: accountPassword,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setAccountValue(data.productValue);
        setSuccess(true);
        setErrors({});
      } else {
        setErrors({
          detail: data.error,
        });
      }
    } catch (reason) {
      setErrors({
        detail: "Error connecting to server. Please try again later.",
      });
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  const tradeAccount = async () => {
    setSuccess(false);
    setErrors({});

    try {
      setLoading(true);
      const response = await fetch("/api/products/purchased/trade/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({
          username: currentProduct.username,
          password: accountPassword,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        setSuccess(true);
        setErrors({});
      } else {
        setErrors({
          detail: data.error,
        });
      }
    } catch (reason) {
      setErrors({
        detail: "Error connecting to server. Please try again later.",
      });
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  const handleProceedClick = () => {
    if (currentStep === 0) valuateAccount();
    else if (currentStep === 1) tradeAccount();
    setCurrentStep(currentStep + 1);
  };

  const handleBackClick = () => {
    setCurrentStep(0);
    setErrors({});
    setSuccess(true);
  };

  const handleModalClose = () => {
    setCurrentStep(0);
    if (currentStep === 2) {
      refreshTable();
      refreshBalance();
    }
    setOpen(false);
  };

  return (
    <Modal
      open
      closeOnDimmerClick={false}
      className={`trade-modal${isDarkMode ? " dark" : ""}`}
      data-theme={isDarkMode ? "dark" : "light"}
      closeIcon
      onClose={handleModalClose}
      onOpen={() => setOpen(true)}
    >
      <Modal.Header>Trade Account for Balance</Modal.Header>
      <Modal.Content image>
        <ProductCard product={currentProduct} isTradeCard={true} />
        <Modal.Description>
          {!loading && success && getSection(currentStep)}
          {loading && (
            <Loader
              className={`trade-modal-loader ${isDarkMode ? " dark" : "light"}`}
              active
              inline="centered"
            >
              Processing the request. Please wait...
            </Loader>
          )}
          {errors.hasOwnProperty("detail") && (
            <Message error>
              <Icon name="warning circle" />
              {errors.detail}
            </Message>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className="trade-modal-actions">
        <div className="trade-modal-stepper">
          <TradeStepper steps={steps} currentStep={currentStep} />
        </div>
        <Button
          className="trade-modal-actions-back"
          disabled={currentStep !== 1 || loading}
          color="blue"
          onClick={handleBackClick}
        >
          Back
        </Button>
        <Button
          color="red"
          onClick={handleModalClose}
          disabled={loading || (currentStep === steps.length - 1 && success)}
        >
          Cancel
        </Button>
        {currentStep !== steps.length - 1 ? (
          <Button
            color="blue"
            onClick={handleProceedClick}
            disabled={!success || loading}
          >
            Proceed
          </Button>
        ) : (
          <Button
            color="blue"
            onClick={handleModalClose}
            disabled={!success || loading}
            icon
            labelPosition="right"
          >
            Finish
            <Icon name="check" />
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
