import React, { useContext } from "react";
import { Button, Icon, Placeholder, Segment } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";

export default function ProductCardPlaceholder() {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <Segment.Group className="product-card">
      <Segment basic inverted={isDarkMode}>
        <Placeholder inverted={isDarkMode}>
          <Placeholder.Image className="product-card-image" />
        </Placeholder>
      </Segment>
      <Segment inverted={isDarkMode}>
        <Placeholder inverted={isDarkMode}>
          <Placeholder.Line length="very long" />
          <Placeholder.Line length="long" />
          <Placeholder.Line length="medium" />
          <Placeholder.Line length="long" />
        </Placeholder>
      </Segment>
      <Segment inverted={isDarkMode} basic style={{ paddingTop: "0.575em" }}>
        <Button disabled>Show Skin Shards</Button>
        <Button color="twitter" disabled>
          <Icon name="cart" />
          Add to cart
        </Button>
      </Segment>
    </Segment.Group>
  );
}
