import React, { useEffect, useCallback, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Input,
  List,
  Message,
  Modal,
} from "semantic-ui-react";
import Cookies from "js-cookie";
import * as yup from "yup";
import { useFormik } from "formik";
import { countryOptions } from "../../constants.js";

const addCreditCardNumberSpaces = (str) => str.replace(/(.{4})/g, "$1 ").trim();
const removeCreditCardNumberSpaces = (str) => str.replace(/\s/g, "");
const addExpiryDateSlash = (str) =>
  str.replace(/^(\d{2})(.*)$/, function (match, p1, p2) {
    return p2.length > 0 ? p1 + "/" + p2 : p1;
  });
const removeExpiryDateSlash = (str) => str.replace(/\//g, "");

const paramValidation = yup.object({
  fullName: yup.string().required(" "),
  phone: yup
    .string()
    .required(" ")
    .test("test-phone", "Invalid phone number", (value) => {
      if (!value) {
        return true;
      }
      return parseInt(value) > 0;
    }),
  creditCardNumber: yup
    .string()
    .required(" ")
    .min(15, "Invalid credit card number")
    .max(16, "Invalid credit card number")
    .test("test-credit-card-number", "Invalid credit card number", (value) => {
      if (!value) {
        return true;
      }
      return parseInt(value.replace(/\s/g, "")) > 0;
    }),
  expiryDate: yup
    .string()
    .required(" ")
    .max(4, "Invalid expiry date")
    .min(4, "Invalid expiry date")
    .test("test-expiry-date", "Invalid expiry date", (value) => {
      if (!value) {
        return true;
      }
      if (
        value.slice(-2) >=
        new Date().toLocaleDateString("en-US", { year: "2-digit" })
      ) {
        return true;
      }
    }),
  cvv: yup
    .string()
    .min(3, "Invalid CVV")
    .max(4, "Invalid CVV")
    .required(" ")
    .matches(/^[0-9]+$/, "Invalid CVV"),
});

export const ParamModal = ({
  open,
  setOpen,
  paramAmount,
  url,
  payload,
  header,
  content,
  description = null,
  promoComponent = null,
}) => {
  const initialValues = {
    fullName: "",
    country: "Spain",
    phone: "",
    creditCardNumber: "",
    expiryDate: "",
    cvv: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema: paramValidation,
    onSubmit: () => {},
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFullNameChange = (e) => {
    if (
      (e.target.value !== "" && /[0-9@#$%^&*~?+!]+/.test(e.target.value)) ||
      e.target.value.length > 255
    ) {
      return;
    }
    formik.setFieldValue("fullName", e.target.value);
  };

  const handleCreditCardChange = (e) => {
    const cleanedCreditCardNumber = e.target.value.replace(/\s/g, "");
    if (
      (cleanedCreditCardNumber !== "" &&
        !/^[0-9]+$/.test(cleanedCreditCardNumber)) ||
      cleanedCreditCardNumber.length > 16
    ) {
      return;
    }
    formik.setFieldValue(
      "creditCardNumber",
      removeCreditCardNumberSpaces(e.target.value),
    );
  };

  const handleCountryChange = (e, { value }) => {
    formik.setFieldValue("country", value);
  };

  const handlePhoneChange = (e) => {
    if (
      (e.target.value !== "" && !/^\+?[0-9]+$/.test(e.target.value)) ||
      e.target.value.length > 15
    ) {
      return;
    } else {
      formik.setFieldValue("phone", e.target.value);
    }
  };

  const handleExpiryDate = (e) => {
    const cleanedExpiryDate = removeExpiryDateSlash(e.target.value);
    if (
      (cleanedExpiryDate !== "" && !/^\+?[0-9]+$/.test(cleanedExpiryDate)) ||
      cleanedExpiryDate.length > 4
    ) {
      return;
    } else if (
      cleanedExpiryDate.length < 2 &&
      parseInt(cleanedExpiryDate) > 1
    ) {
      return formik.setFieldValue("expiryDate", "0" + e.target.value);
    } else if (
      cleanedExpiryDate.length < 3 &&
      parseInt(cleanedExpiryDate) > 12
    ) {
      return formik.setFieldValue("expiryDate", "0" + e.target.value);
    }
    formik.setFieldValue("expiryDate", removeExpiryDateSlash(e.target.value));
  };

  const handleCvv = (e) => {
    if (
      (e.target.value !== "" && !/^[0-9]+$/.test(e.target.value)) ||
      e.target.value.length > 4
    ) {
      return;
    } else {
      formik.setFieldValue("cvv", e.target.value);
    }
  };

  const checkout = useCallback(
    async (values) => {
      if (
        values.creditCardNumber === "" ||
        values.cvv === "" ||
        values.expiryDate === "" ||
        values.fullName === "" ||
        values.country === "" ||
        values.phone === ""
      ) {
        return;
      }
      try {
        setLoading(true);
        setError("");
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
          body: JSON.stringify({
            ...payload,
            ...values,
            expiryMonth: values.expiryDate.slice(0, 2),
            expiryYear: "20" + values.expiryDate.slice(-2),
          }),
        });
        if (!response.ok) {
          try {
            const json = await response.json();
            if (json.fullName) {
              setError(json.fullName[0]);
              setLoading(false);
              return;
            }
            if (json.phone) {
              setError(json.phone[0]);
              setLoading(false);
              return;
            }
            if (json.expiryMonth) {
              setError(json.expiryMonth[0]);
              setLoading(false);
              return;
            }
            if (json.expiryYear) {
              setError(json.expiryYear[0]);
              setLoading(false);
              return;
            }
            if (json.totalPrice) {
              setError(json.totalPrice[0]);
              setLoading(false);
              return;
            }
            if (json.creditCardNumber) {
              setError(json.creditCardNumber[0]);
              setLoading(false);
              return;
            }
            if (json.cvv) {
              setError(json.cvv[0]);
              setLoading(false);
              return;
            }
            if (json.detail) {
              setError(json.detail);
              setLoading(false);
              return;
            } else if (json.cartItems) {
              setError("Please reset your cart and try again.");
              setLoading(false);
              return;
            } else {
              setError("Something went wrong.");
              setLoading(false);
              return;
            }
          } catch (error) {
            setError("Something went wrong.");
            setLoading(false);
            return;
          }
        }
        if (response.status === 200) {
          // the response is redirect_url
          const json = await response.json();
          window.location.href = json.redirectUrl;
          return;
        }
      } catch (error) {
        setError("Something went wrong.");
        setLoading(false);
      }
    },
    [payload, url],
  );

  const handleSubmit = useCallback(() => {
    formik.handleSubmit();
    formik.isValid && checkout(formik.values);
  }, [checkout, formik]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleSubmit]);

  return (
    <Modal
      closeIcon
      closeOnDimmerClick={false}
      onClose={() => setOpen(false)}
      open={open}
      size="tiny"
      className="modal"
    >
      <Header content={header} />
      <Modal.Content>
        <div className="param-checkout">
          <div className="param-form">
            <Grid>
              <Grid.Column floated="left" width={7}>
                <Header as="h4" content={content} subheader={description} />
              </Grid.Column>
              <Grid.Column floated="right" width={3}>
                <Header as="h3">
                  {paramAmount.toLocaleString(undefined, {
                    style: "currency",
                    currency: "EUR",
                  })}
                </Header>
              </Grid.Column>
            </Grid>
            {promoComponent}
            <Divider hidden />
            <Form error={!formik.isValid || Boolean(error)}>
              <Form.Group>
                <Form.Input
                  width="9"
                  onChange={handleFullNameChange}
                  name="fullName"
                  placeholder="Full Name"
                  value={formik.values.fullName}
                  label="Full Name"
                  error={
                    formik.touched.fullName && Boolean(formik.errors.fullName)
                  }
                />
                <Form.Field width="10">
                  <label>Phone Number</label>
                  <Input
                    onChange={handlePhoneChange}
                    value={formik.values.phone}
                    placeholder="Phone Number"
                    name="phone"
                    label={
                      <Dropdown
                        inline
                        basic
                        scrolling
                        value={formik.values.country}
                        onChange={handleCountryChange}
                        options={countryOptions}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                      />
                    }
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  onChange={handleCreditCardChange}
                  name="creditCardNumber"
                  value={addCreditCardNumberSpaces(
                    formik.values.creditCardNumber,
                  )}
                  placeholder="1234 1234 1234 1234"
                  label="Credit Card Number"
                  width={7}
                  error={
                    formik.touched.creditCardNumber &&
                    Boolean(formik.errors.creditCardNumber)
                  }
                />
                <Form.Input
                  onChange={handleExpiryDate}
                  value={addExpiryDateSlash(formik.values.expiryDate)}
                  name="expiryDate"
                  placeholder="MM/YY"
                  label="Expiry Date"
                  width={4}
                  error={
                    formik.touched.expiryDate &&
                    Boolean(formik.errors.expiryDate)
                  }
                />
                <Form.Input
                  onChange={handleCvv}
                  value={formik.values.cvv}
                  name="cvv"
                  placeholder="CVV"
                  type="password"
                  label="CVV"
                  width={3}
                  error={formik.touched.cvv && Boolean(formik.errors.cvv)}
                />
              </Form.Group>
              <Divider hidden />
              <Message error>
                <List bulleted>
                  {error ? <List.Item>{error}</List.Item> : null}
                  {!formik.isValid ? (
                    <List.Item>All fields are required.</List.Item>
                  ) : null}
                  {Object.values(formik.errors).map(
                    (error) =>
                      error !== " " && (
                        <List.Item key={error}>{error}</List.Item>
                      ),
                  )}
                </List>
              </Message>
            </Form>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={`Pay ${paramAmount.toLocaleString(undefined, {
            style: "currency",
            currency: "EUR",
          })}`}
          labelPosition="right"
          icon="right arrow"
          primary
          type="submit"
          disabled={loading || !formik.isValid}
          loading={loading}
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
};
