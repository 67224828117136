import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "semantic-ui-react";
import { displayName } from "../constants";
import DarkModeContext from "../contexts/DarkModeContext";
import Loading from "./Loading";
import NothingFound from "./NothingFound";
import ProductPaginator from "./ProductPaginator";
import YourPaymentsTable from "./YourPaymentsTable";

const useQuery = () => new URLSearchParams(useLocation().search);
const path = "/my-account/profile/your-payments/";

export default function YourPaments() {
  const query = useQuery();
  const { isDarkMode } = useContext(DarkModeContext);

  const [loading, setLoading] = useState(true);
  const [paymentItems, setPaymentItems] = useState({ results: [] });
  const [search, setSearch] = useState(() => {
    // Set page to 1 if not initialized
    if (!query.get("page")) {
      query.set("page", 1);
    }
    // Set limit to 5 if not initialized
    if (!query.get("limit")) {
      query.set("limit", 5);
    }
    return `?${query.toString()}`;
  });

  const fetchTransactions = useCallback(
    async (signal) => {
      try {
        setLoading(true);
        const response = await fetch("/api/user/payment-items/" + search, {
          signal,
        });
        if (response.ok) {
          setPaymentItems(await response.json());
        }
        setLoading(false);
      } catch (reason) {
        if (reason.name === "AbortError") return;
        setLoading(false);
        console.log(reason);
      }
    },
    [search],
  );

  useEffect(() => {
    const controller = new AbortController();

    document.title = `${displayName} | Your Payments`;
    fetchTransactions(controller.signal);
    return () => controller.abort();
  }, [fetchTransactions]);

  const noResults = loading || paymentItems.results.length === 0;

  const table = loading ? (
    <Loading size="big" />
  ) : noResults ? (
    <NothingFound />
  ) : (
    <>
      <YourPaymentsTable paymentItems={paymentItems.results} />
      <ProductPaginator
        products={paymentItems}
        path={path}
        onChange={(location) => setSearch(location)}
      />
    </>
  );

  return (
    <>
      <Header inverted={isDarkMode}>Your Payments</Header>
      {table}
    </>
  );
}
