import React, { useContext } from "react";
import { Button, Popup } from "semantic-ui-react";
import SkinContext from "../contexts/SkinContext";

const getTitle = (skinShards, id, relevantSkins, type) => {
  const skin = skinShards.find((s) => id === s.id);
  if (skin === undefined) return id;
  let skinTitle = skin.title + (type ? ` (${type})` : "");
  return relevantSkins?.includes(id) ? <b>{skinTitle}</b> : skinTitle;
};

function ShowSkinsPopup({
  skins,
  permanent_skins,
  owned_skins,
  relevantSkins,
  button,
}) {
  const { skinShards } = useContext(SkinContext);
  const skinCount = skins.length + permanent_skins.length + owned_skins.length;
  return (
    <Popup trigger={button}>
      <Popup.Content>
        {skinCount === 0 ? (
          "This account has no skin shards."
        ) : (
          <>
            {skins.map((skin, index) => (
              <div key={index}>{getTitle(skinShards, skin, relevantSkins)}</div>
            ))}
            {permanent_skins.map((skin, index) => (
              <div key={index}>
                {getTitle(skinShards, skin, relevantSkins, "Permanent")}
              </div>
            ))}
            {owned_skins.map((skin, index) => (
              <div key={index}>
                {getTitle(skinShards, skin, relevantSkins, "Owned")}
              </div>
            ))}
          </>
        )}
      </Popup.Content>
    </Popup>
  );
}

ShowSkinsPopup.defaultProps = {
  button: <Button>Show Skin Shards</Button>,
};

export default ShowSkinsPopup;
