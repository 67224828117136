import React, { useContext, useState } from "react";
import { Button, Icon, Form, Message, Header } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";
import ExportTable, { generateFilesLimit } from "./ExportTable";
import { displayName } from "../constants";

const errorMessage =
  "Some error occured when generating the file. " +
  // "Try generating individual files or use 'Send by email'.";
  "Try generating individual files or contact Support.";

const largeDataErrorMessage =
  "Large data sets generation is not currently supported. " +
  // "Try generating individual files or use 'Send by email'.";
  "Try generating individual files or contact Support.";

const noDataErrorMessage = "No data found.";

const contentTypeOptions = [
  { key: "all", text: "All data", value: "all" },
  { key: "accounts", text: "Accounts", value: "accounts" },
  {
    key: "transactions",
    text: "Transactions",
    value: "transactions",
  },
  { key: "payments", text: "Payments", value: "payments" },
];

const fileFormatOptions = [
  { key: "csv", text: "CSV", value: "csv" },
  { key: "json", text: "JSON", value: "json" },
];

function getBlobContent(format, data) {
  switch (format) {
    case "json":
      return JSON.stringify(data, null, 2);
    case "csv":
      return [Object.keys(data[0]), ...data.map((i) => Object.values(i))].join(
        "\n",
      );
    default:
      return null;
  }
}
function getMimeType(format) {
  switch (format) {
    case "json":
      return "application/json;charset=utf-8";
    case "csv":
      return "text/csv;charset=utf-8";
    default:
      return null;
  }
}

function generateFiles(data, fileFormat, files) {
  const now = new Date();
  const nowDMY = now.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  // remove characters from date that are not allowed in file names
  const nowStr = now.toISOString().replace(/[:.-]/g, "");
  for (const [key, value] of Object.entries(data)) {
    if (value.length === 0) {
      continue;
    }
    const fileName = `${displayName}_${key}_${nowStr}.${fileFormat}`;
    const el = document.createElement("a");
    const blobContent = getBlobContent(fileFormat, value);
    if (blobContent === null) {
      throw new Error("Couldnot convert object");
    }
    const blob = new Blob([blobContent], {
      type: getMimeType(fileFormat),
    });
    el.href = window.URL.createObjectURL(blob);
    el.download = fileName;
    files.push({
      type: key,
      name: fileName,
      element: el,
      format: fileFormat,
      rows: value.length,
      date: nowDMY,
      size: blob.size,
    });
  }
}

export default function ExportData({ title }) {
  const [files, setFiles] = useState([]);
  const [contentType, setContentType] = useState(["accounts"]);
  const [fileFormat, setFileFormat] = useState("csv");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isDarkMode } = useContext(DarkModeContext);

  const fetchContents = async () => {
    try {
      setSuccess(false);
      console.log(contentType, fileFormat);
      if (contentType.length === 0 || !fileFormat) {
        setError("Please select content type and file format");
        return;
      }
      if (files.length >= generateFilesLimit) {
        setError(
          "File generation limit reached. Please remove some files to continue.",
        );
        return;
      }
      const duplicateFileReq = files
        .filter((item) => contentType.includes(item.type))
        .map((item) => item.type);
      if (duplicateFileReq.length > 0) {
        setError(
          `Files with content: '${duplicateFileReq.join(
            ", ",
          )}' is already generated. Remove the files to continue.`,
        );
        return;
      }
      setError(null);
      setLoading(true);
      const queryParam = new URLSearchParams();
      contentType.forEach((c) => {
        queryParam.append(c, true);
      });
      const response = await fetch(
        "/api/export-data/file/?" + queryParam.toString(),
      );
      if (response.ok) {
        setSuccess(true);
        setError(null);
        const fetchedContents = await response.json();
        // generate files from content
        const generatedFiles = files;
        generateFiles(fetchedContents, fileFormat, generatedFiles);
        setFiles(generatedFiles);
      } else if (response.status === 419) {
        setError(largeDataErrorMessage);
      } else if (response.status === 420) {
        setError(noDataErrorMessage);
      } else {
        setError(errorMessage);
      }
    } catch (reason) {
      if (reason.name === "AbortError") return;
      setSuccess(false);
      setError(errorMessage);
      // console.log removed
    } finally {
      setLoading(false);
    }
  };

  const onTypeChange = (event, data) => {
    // if all, select all options
    if (event.target.textContent === "All data") {
      setContentType(
        data.options.filter((d) => d.value !== "all").map((d) => d.value),
      );
    } else {
      setContentType(data.value);
    }
  };

  const onFormatChange = (event, data) => {
    setFileFormat(data.value);
  };

  return (
    <>
      <Header inverted={isDarkMode}>Export Data</Header>
      <div className="export-data-wrapper">
        <div className="generate-file">
          <Message
            attached
            color={isDarkMode ? "black" : "grey"}
            header={title || "Generate File"}
            content="Select the file type and format you want to export."
          />
          <Form
            inverted={isDarkMode}
            className="generate-file-form attached segment"
            success={success}
            error={error != null}
          >
            <Form.Dropdown
              disabled={loading}
              multiple
              fluid
              placeholder="Content type"
              selection
              value={contentType}
              onChange={onTypeChange}
              options={contentTypeOptions}
            />
            <Form.Dropdown
              disabled={loading}
              fluid
              placeholder="File format"
              selection
              value={fileFormat}
              onChange={onFormatChange}
              options={fileFormatOptions}
            />
            <div className="generate-file-buttons">
              <Button
                fluid
                disabled={loading}
                color="twitter"
                onClick={fetchContents}
              >
                Generate
              </Button>
              <Button fluid disabled color="twitter">
                Send by email
              </Button>
            </div>
            <Message attached info>
              <Icon name="info circle" />
              Please wait before creating multiple concurrent export requests.
            </Message>
            <Message
              success
              header="Request Successful"
              content="Your file is being generated. You can download the file in the table."
            />
            <Message error header="Error Generating File" content={error} />
          </Form>
        </div>
        <div className="export-data-table-wrapper">
          <ExportTable files={files} setFiles={setFiles} />
        </div>
      </div>
    </>
  );
}
