import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from "./Loading";
import NoResultsFound from "./NoResultsFound";
import ProductPaginator from "./ProductPaginator";
import { TradedAccountsTable } from "./AccountsTable";

const path = "/my-account/profile/your-purchases/traded-accounts/";

export const TradedAccounts = ({ search, setSearch }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState({ results: [] });

  const fetchTradedAccounts = useCallback(
    async (signal) => {
      try {
        setLoading(true);
        const response = await fetch("/api/products/traded/" + search, {
          signal,
        });
        if (response.status === 429) {
          history.push("/429/");
        }
        if (response.ok) {
          const json = await response.json();
          setProducts(json);
        }
        setLoading(false);
      } catch (reason) {
        if (reason.name === "AbortError") return;
        setLoading(false);
        console.log(reason);
      }
    },
    [search, history],
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchTradedAccounts(controller.signal);
    return () => controller.abort();
  }, [fetchTradedAccounts]);

  const noResults = loading || products.results.length === 0;
  const table = loading ? (
    <Loading size="big" />
  ) : noResults ? (
    <NoResultsFound title="No traded accounts found." />
  ) : (
    <>
      <TradedAccountsTable products={products.results} />
      <ProductPaginator
        products={products}
        path={path}
        onChange={(location) => setSearch(location)}
      />
    </>
  );
  return <>{table}</>;
};
