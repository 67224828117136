import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { Button, Form, Message } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";

const errorMessage =
  "Some error occured when redeeming the provided coupon. " +
  "Please make sure that the coupon code is entered correctly and has not already been used.";

export default function RedeemCoupon({ title, refreshBalance }) {
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState();
  const [success, setSuccess] = useState(false);
  const [redeemerror, setRedeemError] = useState();
  const [loading, setLoading] = useState(false);

  const redeemCode = async () => {
    setSuccess(false);
    setCodeError(null);
    setRedeemError(null);

    if (code === "" || code === null) {
      setCodeError("This field may not be blank.");
      return;
    }
    try {
      setLoading(true);
      const response = await fetch("/api/redeem-coupon/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({ code }),
      });
      if (response.ok) {
        setSuccess(true);
        setCodeError(null);
        setRedeemError(null);
        refreshBalance();
      } else if (response.status === 400) {
        const json = await response.json();
        setRedeemError(json.detail);
      } else {
        setRedeemError(errorMessage);
      }
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <div className="redeem-coupon-form attatched-from">
      <Message
        attached
        color={isDarkMode ? "black" : "grey"}
        header={title || "Redeem Coupon"}
        content="Enter the coupon code below to redeem your coupon."
      />
      <Form
        inverted={isDarkMode}
        className="attached segment"
        loading={loading}
        success={success}
        error={redeemerror != null}
      >
        <Form.Input
          placeholder="Enter coupon code"
          type="text"
          onChange={(e, { value }) => setCode(value)}
          value={code}
          error={codeError}
        />
        <Message
          success
          header="Coupon Redeemed Succesfully"
          content="The coupon has been redeemed successfully. You can use your redeemed balance now."
        />
        <Message error header="Error Redeeming Coupon" content={redeemerror} />
        <Button color="twitter" onClick={redeemCode} disabled={loading}>
          Submit
        </Button>
      </Form>
    </div>
  );
}
