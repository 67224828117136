import { useContext } from "react";
import { Table } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";

export default function YourTransactionsTable({ transactions }) {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <Table inverted={isDarkMode}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {transactions.map((transaction) => (
          <Table.Row key={transaction.id}>
            <Table.Cell>
              <p
                style={{
                  color:
                    transaction.amount > 0 ? "var(--success)" : "var(--danger)",
                }}
              >
                {new Date(transaction.date).toLocaleDateString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </p>
            </Table.Cell>
            <Table.Cell>
              <p
                style={{
                  color:
                    transaction.amount > 0 ? "var(--success)" : "var(--danger)",
                }}
              >
                {parseFloat(transaction.amount).toLocaleString(undefined, {
                  style: "currency",
                  currency: "EUR",
                })}
              </p>
            </Table.Cell>
            <Table.Cell>
              <p
                style={{
                  color:
                    transaction.amount > 0 ? "var(--success)" : "var(--danger)",
                }}
              >
                {transaction.description}
              </p>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
