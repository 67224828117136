import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import NoResultsFound from "../../components/NoResultsFound";
import { Checkbox } from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";
import PriceContext from "../../contexts/PricesContext";
import SkinContext from "../../contexts/SkinContext";
import {
  getIsOldStock,
  getLastPlayedDateObject,
  getProductTags,
  getThumbnail,
} from "../../utils";
import BidCard from "./BidCard";

export const YourBids = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [bids, setBids] = useState({ results: [] });
  const [isActive, setIsActive] = useState(true);
  const { isDarkMode } = useContext(DarkModeContext);
  const [detailedBids, setDetailedBids] = useState([]);

  const { skinShards } = useContext(SkinContext);
  const { prices } = useContext(PriceContext);

  const showResults = useCallback(
    (bids, skinShards) => {
      let output = [...bids];
      let interestedSkins = [];
      const mythicSkins = skinShards.reduce((filtered, skin) => {
        if (skin.tier === "MYTHIC") filtered.push(skin.id);
        return filtered;
      }, []);

      output = output.map((o) => {
        if (o.product.soloWins === "") o.product.soloWins = null;
        if (o.product.soloLosses === "") o.product.soloLosses = null;

        const productSkins = [
          ...o.product.skins,
          ...o.product.permanentSkins,
          ...o.product.ownedSkins,
        ];
        const mythicCount = productSkins.filter((skin) =>
          mythicSkins.includes(skin),
        ).length;

        o.product["isOldStock"] = getIsOldStock(
          getLastPlayedDateObject(o.product.dateLastPlayed?.slice(0, 10)),
          prices,
        );
        const [tags, label_tags] = getProductTags(
          o.product,
          {},
          mythicCount,
          prices,
        );
        o.product["tags"] = tags;
        o.product["label_tags"] = label_tags;

        const thumbnailSkins = productSkins;

        const [thumbnail, thumbnailSkin] = getThumbnail(
          skinShards,
          thumbnailSkins,
        );
        o.product["thumbnail"] = thumbnail;
        o.product["relevantSkins"] = [...interestedSkins, ...[thumbnailSkin]];
        return o;
      });
      setDetailedBids(output);
    },
    [prices],
  );

  const fetchBids = useCallback(
    async (signal) => {
      try {
        setFetching(true);
        const response = await fetch(
          `/api/marketplace/user/bids/?is_active=${isActive}`,
          {
            signal,
          },
        );
        if (response.status === 429) {
          history.push("/429/");
        }
        if (response.ok) {
          const json = await response.json();
          setBids(json);
        }
        setFetching(false);
      } catch (reason) {
        if (reason.name === "AbortError") return;
        setFetching(false);
        console.log(reason);
      }
    },
    [history, isActive],
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchBids(controller.signal);
    return () => controller.abort();
  }, [fetchBids]);

  useEffect(() => {
    setLoading(true);
    showResults(bids.results, skinShards);
    setLoading(false);
  }, [bids, skinShards, showResults]);

  const renderBids = () => (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill, minmax(298px, 298px))`,
        gap: "20px",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {detailedBids.map((bid) => (
        <div key={bid.product.id} data-aos="fade-up" data-aos-duration={500}>
          <BidCard bid={bid} isActive={isActive} alt="product-image" />
        </div>
      ))}
    </div>
  );

  const noResults = loading || bids.results.length === 0;
  const cards =
    loading || fetching ? (
      <Loading size="big" />
    ) : noResults ? (
      <NoResultsFound
        title={`No ${isActive ? "active" : "inactive"} bids found`}
      />
    ) : (
      <>{renderBids()}</>
    );
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "10px",
          gap: "8px",
        }}
      >
        <strong>Active Bids</strong>
        <Checkbox
          toggle
          checked={isActive}
          onChange={() => {
            setIsActive(!isActive);
          }}
          className={isDarkMode ? "active-bids-toggle" : ""}
        />
      </div>
      {cards}
    </>
  );
};
