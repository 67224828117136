import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { Button, Container, Message } from "semantic-ui-react";
import CartContext from "../contexts/CartContext";

export default function PaymentProcessing() {
  const history = useHistory();

  const { refreshCart } = useContext(CartContext);

  useEffect(() => refreshCart(), [refreshCart]);

  return (
    <Container style={{ padding: "1em" }}>
      <Message positive>
        Your card payment is being processed. The items will be delivered in
        your purchases after the payment is successful.
      </Message>
      <Button
        onClick={() =>
          history.push({
            pathname: "/my-account/profile/your-payments/",
            search: "?page=1&limit=5",
          })
        }
      >
        Check my payments
      </Button>
    </Container>
  );
}
