import { useContext } from "react";
import { Icon, Table } from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";

export const TradedAccountsTable = ({ products }) => {
  const { isDarkMode } = useContext(DarkModeContext);
  return (
    <div className="your-purchases-wrapper">
      <Table inverted={isDarkMode} singleLine stackable={false}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Traded Date</Table.HeaderCell>
            <Table.HeaderCell>Account Username</Table.HeaderCell>
            <Table.HeaderCell>Verified</Table.HeaderCell>
            <Table.HeaderCell>Date Verified</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {products.map((product, index) => (
            <Table.Row key={index}>
              <Table.Cell>
                {new Date(product.dateTraded).toLocaleDateString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </Table.Cell>
              <Table.Cell>{product.productUsername}</Table.Cell>
              <Table.Cell>
                {product.isVerified ? (
                  <Icon name="check circle" color="green" />
                ) : (
                  <Icon name="remove circle" color="red" />
                )}
              </Table.Cell>
              <Table.Cell>
                {product.dateVerified
                  ? new Date(product.dateVerified).toLocaleDateString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })
                  : "-"}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};
