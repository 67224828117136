import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Message } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const history = useHistory();

  const { isDarkMode } = useContext(DarkModeContext);

  const changePassword = async () => {
    try {
      setLoading(true);
      const response = await fetch("/api/auth/password/change/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({ oldPassword, newPassword1, newPassword2 }),
      });
      if (response.ok) {
        setSuccess(true);
        setErrors("");
        setOldPassword("");
        setNewPassword1("");
        setNewPassword2("");
        return;
      }
      if (response.status === 429) {
        history.push("/429/");
      }
      setErrors(await response.json());
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="change-password-form attatched-from">
      <Message
        attached
        color={isDarkMode ? "black" : "grey"}
        header="Change Password"
      />
      <Form
        inverted={isDarkMode}
        loading={loading}
        success={success}
        className="attached segment"
      >
        <Form.Input
          label="Old Password"
          placeholder="Old Password"
          type="password"
          error={errors.oldPassword}
          value={oldPassword}
          onChange={(e, { value }) => setOldPassword(value)}
        />
        <Form.Input
          label="New Password"
          placeholder="New password"
          type="password"
          error={errors.newPassword1}
          value={newPassword1}
          onChange={(e, { value }) => setNewPassword1(value)}
        />
        <Form.Input
          label="Confirm New Password"
          placeholder="Confirm New Password"
          type="password"
          error={errors.newPassword2}
          value={newPassword2}
          onChange={(e, { value }) => setNewPassword2(value)}
        />
        <Message
          success
          header="Password Changed Succesfully"
          content="Your password has been changed. You may now login with your new password."
        />
        <Button color="twitter" onClick={changePassword} disabled={loading}>
          Submit
        </Button>
      </Form>
    </div>
  );
}
