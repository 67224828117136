import { Icon, Message } from "semantic-ui-react";

const ValidationError = ({ error }) => {
  const errorStyle = {
    padding: 0,
    margin: "3px 0",
    color: "#F54b48",
    background: "none",
    border: "none",
    outline: "none",
    boxShadow: "none",
  };

  if (!error) return <></>;

  return (
    <Message error visible style={errorStyle}>
      <Icon name="info circle" />
      {error}
    </Message>
  );
};

export default ValidationError;
