import { Icon, Message } from "semantic-ui-react";
import { ParamModal } from "../Modal/ParamModal";

export const ParamCheckoutModal = ({
  open,
  setOpen,
  paramAmount,
  totalAmount,
  cartItems,
  discountCode,
  description,
}) => {
  return (
    <ParamModal
      open={open}
      setOpen={setOpen}
      paramAmount={Number(paramAmount)}
      url="/api/make-param-payment/"
      payload={{
        totalPrice: totalAmount,
        cartItems: Object.values(cartItems).map((cartitem) => cartitem.id),
        ...(discountCode && { discountCode }),
      }}
      header="Credit Card Checkout"
      content="Order Summary:"
      description={description}
      promoComponent={
        <Message success>
          <Icon name="info circle" />
          You will receive 10% cashback as account balance on successful
          checkout.
        </Message>
      }
    />
  );
};
