import React, { useState, useContext } from "react";
import { Accordion } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import DarkModeContext from "../../contexts/DarkModeContext";
const faq = require("../../json/faq.json");

export default function Faq() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { isDarkMode } = useContext(DarkModeContext);

  const handleClick = (e, index) => {
    if (activeIndex === index) return setActiveIndex(-1);
    setActiveIndex(index);
  };

  return (
    <Accordion className="faq-accordion">
      {faq.map((item, index) => {
        const isOpen = activeIndex === index;
        return (
          <div
            key={index}
            className="faq-item"
            style={{
              backgroundColor: isDarkMode ? "#052535" : "#F8F8F9",
            }}
          >
            <Accordion.Title
              styled="true"
              active={isOpen}
              onClick={(e) => handleClick(e, index)}
              className="faq-title"
            >
              <p>{item.title}</p>
              <FontAwesomeIcon
                icon={faChevronDown}
                className={isOpen ? "faq-icon rotate" : "faq-icon"}
                size="lg"
                style={{
                  color: isDarkMode ? "#e1e1e1" : "#000",
                }}
              />
            </Accordion.Title>
            <div className={isOpen ? "faq-content opened" : "faq-content"}>
              <p>{item.content}</p>
            </div>
          </div>
        );
      })}
    </Accordion>
  );
}
