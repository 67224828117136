import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { Button, Header, Icon, Modal, Popup } from "semantic-ui-react";
import Error from "../../components/Errors";
import CartContext from "../../contexts/CartContext";
import DarkModeContext from "../../contexts/DarkModeContext";

export default function InstaBuyPopup({
  product,
  isInstaBuyPlaced,
  setIsInstaBuyPlaced,
}) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [forbidden, setForbidden] = useState(false);
  const { refreshCart } = useContext(CartContext);
  const [isModalOpen, setIsModelOpen] = useState(false);
  const { isDarkMode } = useContext(DarkModeContext);
  const [isHovered, setIsHovered] = useState(false);

  const handleInstantBuy = async () => {
    setLoading(true);
    const body = {
      product: product.product.id,
      amount: product.instaBuyAmount,
      isInstaBuy: true,
    };

    try {
      setErrors({});
      setLoading(true);
      const response = await fetch(
        `/api/marketplace/bids/${product.product.id}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
          body: JSON.stringify(body),
        },
      );
      if (response.status === 403) {
        setForbidden(true);
        setIsModelOpen(false);
        return;
      }
      if (!response.ok) {
        const error = await response.json();
        setErrors(error);
        setLoading(false);
        return;
      }
      if (response.status === 201) {
        refreshCart();
        setIsModelOpen(false);
        setIsInstaBuyPlaced(true);
      }
    } catch (error) {
      setErrors({
        error: error.detail,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setIsModelOpen(false);
    setErrors({});
  };

  const handleProceed = async () => {
    try {
      setLoading(true);
      const response = await fetch("/api/auth/user/");
      if (response.status === 403) {
        setForbidden(true);
        return;
      }
      if (response.ok) {
        setIsModelOpen(true);
      }
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Popup
        on="click"
        open={
          forbidden ||
          (isHovered && (!product.instaBuyAmount || product.isUserOwner))
        }
        trigger={
          <div
            style={{ width: "100%" }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Button
              fluid
              disabled={
                loading ||
                !product.instaBuyAmount ||
                isInstaBuyPlaced ||
                product.isUserOwner
              }
              loading={loading}
              color="twitter"
              onClick={() => handleProceed()}
            >
              {isInstaBuyPlaced ? (
                <>
                  <Icon name={"check circle"} />
                  Product Bought
                </>
              ) : (
                <>
                  <Icon name={"cart"} />
                  Insta Buy
                  {product.instaBuyAmount && ` (€${product.instaBuyAmount})`}
                </>
              )}
            </Button>
          </div>
        }
        onClose={() => setForbidden(false)}
      >
        <Popup.Content>
          {isHovered &&
            (!product.instaBuyAmount ? (
              <>
                <p>The owner has disabled insta buy on this account .</p>
              </>
            ) : product.isUserOwner ? (
              <>
                <p>Cannot buy your own product.</p>
              </>
            ) : (
              <></>
            ))}
          {forbidden && (
            <>
              <p>Login to instant buy</p>
              <Button.Group>
                <Button
                  color="teal"
                  onClick={() => {
                    window.open("/my-account/");
                    setForbidden(false);
                  }}
                >
                  Login
                </Button>
                <Button.Or />
                <Button
                  onClick={() => {
                    window.open("/my-account/");
                    setForbidden(false);
                  }}
                >
                  Register
                </Button>
              </Button.Group>
            </>
          )}
        </Popup.Content>
      </Popup>
      {isModalOpen && (
        <Modal
          open
          closeOnDimmerClick={false}
          className={`trade-modal${isDarkMode ? " dark" : ""}`}
          data-theme={isDarkMode ? "dark" : "light"}
          closeIcon
          onClose={handleModalClose}
          onOpen={() => setIsModelOpen(true)}
          size={"mini"}
        >
          <Modal.Header>Instant Buy</Modal.Header>
          <Modal.Content image>
            <div style={{ width: "100%" }}>
              <Header inverted={isDarkMode}>
                Confirm Instant Buy for €{product.instaBuyAmount}?
              </Header>
              <Error errors={errors} />
            </div>
          </Modal.Content>

          <Modal.Actions className="trade-modal-actions">
            <Button
              color="twitter"
              onClick={handleInstantBuy}
              disabled={loading}
              loading={loading}
            >
              Buy
            </Button>
            <Button color="red" onClick={handleModalClose} disabled={loading}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
}
