import { faCircleInfo, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "semantic-ui-react";
import Cookies from "js-cookie";
import React from "react";
import { Checkbox, Icon, Label, LabelGroup, Popup } from "semantic-ui-react";
import { getRegion } from "../../utils";
import InfoPopup from "../InfoPopup";
import ShowSkinsPopup from "../ShowSkinsPopup";
import { rankedValues } from "../../constants";

const getSkinCount = (cartItem) => {
  try {
    return (
      cartItem.skins.length +
      cartItem.permanentSkins.length +
      cartItem.ownedSkins.length
    );
  } catch {
    return 0;
  }
};

export default function CartItem({
  cartItem,
  selectedCartItems,
  setSelectedCartItems,
  toggleItemSelected,
  setPreselect,
  setCartItems,
}) {
  const [loading, setLoading] = React.useState(false);
  const deleteCartItem = async (cartItem) => {
    setLoading(true);
    try {
      const response = await fetch("/api/user/cart/", {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({ cart_items: [cartItem?.id] }),
      });
      if (!response.ok) {
        setLoading(false);
        alert(`Could not delete cart items. Status: ${response.status}`);
        return;
      }
      setCartItems((prev) => prev.filter((item) => item.id !== cartItem?.id));
      setPreselect(false);
      setSelectedCartItems(
        Object.fromEntries(
          Object.entries(selectedCartItems).filter(
            ([key, value]) => Number(key) !== cartItem?.id,
          ),
        ),
      );
      setLoading(false);
    } catch (reason) {
      console.log(reason);
    }
  };

  const renderLabelTags = () => (
    <LabelGroup className="card-labels">
      {cartItem.label_tags?.map((tag, index) => {
        if (tag.description) {
          return (
            <Popup
              key={index}
              trigger={
                <Label key={index} className={tag.color}>
                  {tag.icon && <Icon name={tag.icon} />}
                  {tag.label}
                </Label>
              }
              content={tag.description}
            />
          );
        }
        return (
          <Label key={index} className={tag.color}>
            {tag.icon && <Icon name={tag.icon} />}
            {tag.label}
          </Label>
        );
      })}
    </LabelGroup>
  );

  const SkinShards = (skinCount) => (
    <p style={{ cursor: "pointer" }}>
      <FontAwesomeIcon
        icon={faCircleInfo}
        style={{
          fontSize: "14px",
          color: "var(--neutral-400)",
          marginRight: "5px",
        }}
      />
      Contains {skinCount} Skin(s)
    </p>
  );

  return (
    <>
      <Checkbox
        checked={cartItem.id in selectedCartItems}
        onClick={() => {
          if (cartItem.id in selectedCartItems)
            toggleItemSelected(cartItem, false);
          else toggleItemSelected(cartItem, true);
        }}
        onChange={(e, { checked }) => {
          toggleItemSelected(cartItem, checked);
        }}
      />

      <div className="cart-item-image">
        <img
          src={cartItem.thumbnail}
          alt="product"
          className="cart-item-image"
        />
      </div>

      <div className="cart-product-description">
        <div className="region-level-div">
          <p>
            Region: <b>{getRegion(cartItem.region)}</b>
          </p>
          <p>
            Level: <b>{cartItem.discreteLevel}+</b>
          </p>
        </div>
        <div className="be">
          <p>
            Blue Essence:
            <b>
              {cartItem.discreteBlueEssence <= 0
                ? " <10,000"
                : " " + cartItem.discreteBlueEssence.toLocaleString() + "+"}
            </b>
          </p>
          {cartItem.isHandleveled ? (
            <InfoPopup description="This account contains champion capsules." />
          ) : null}
        </div>
        <p>
          Orange Essence: <b>{cartItem.orangeEssence.toLocaleString()}</b>
        </p>
        {rankedValues.includes(cartItem.rank) && (
          <p>
            Ranked W/L:{" "}
            <b>
              {cartItem.soloWins || " - "}/{cartItem.soloLosses || " - "}
            </b>
          </p>
        )}
        <ShowSkinsPopup
          skins={cartItem.skins}
          permanent_skins={cartItem.permanentSkins}
          owned_skins={cartItem.ownedSkins}
          relevantSkins={cartItem.relevantSkins}
          button={SkinShards(getSkinCount(cartItem))}
        />
      </div>

      <div className="cart-item-left">
        {renderLabelTags()}

        <h2 className="product-price">
          {cartItem.price.toLocaleString(undefined, {
            style: "currency",
            currency: "EUR",
          })}
        </h2>

        <div className="delete-item">
          {loading ? (
            <Loader active inline size="tiny" />
          ) : (
            <FontAwesomeIcon
              icon={faTrash}
              className="cart-item-delete"
              onClick={() => {
                deleteCartItem(cartItem);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
