import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { Button, Header, Modal } from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";
import Error from "../Errors";

export const MarketplaceCancelModal = ({ setOpen, product, refreshTable }) => {
  const { isDarkMode } = useContext(DarkModeContext);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleModalClose = () => {
    setOpen(false);
    success && refreshTable();
  };

  const handleCancel = async () => {
    try {
      setSuccess(false);
      setErrors({});
      setLoading(true);
      const response = await fetch(
        `/api/marketplace/products/${product.product.username}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
        },
      );
      const data = await response.json();
      if (response.ok) {
        setSuccess(true);
        refreshTable()
        setOpen(false)
        setErrors({});
      } else {
        setErrors(data);
        console.log(data.error);
      }
    } catch (reason) {
      setErrors({
        error: "Error connecting to server. Please try again later.",
      });
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open
      closeOnDimmerClick={false}
      className={`trade-modal${isDarkMode ? " dark" : ""}`}
      data-theme={isDarkMode ? "dark" : "light"}
      closeIcon
      onClose={handleModalClose}
      onOpen={() => setOpen(true)}
      size={"mini"}
    >
      <Modal.Header>Cancel Marketplace Product</Modal.Header>
      <Modal.Content image>
        <div>
          <Header inverted={isDarkMode}>
            Are you sure you want to remove this product?
          </Header>
          <Error errors={errors} />
        </div>
      </Modal.Content>

      <Modal.Actions className="trade-modal-actions">
        <Button
          color="twitter"
          onClick={handleCancel}
          disabled={loading}
          loading={loading}
        >
          Ok
        </Button>
        <Button color="red" onClick={handleModalClose} disabled={loading}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
