import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Form, Header, Message, Segment } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";

export default function ForgotPasswordConfirmPage() {
  const { uid, token } = useParams();
  const [loading, setLoading] = useState(false);
  const [newPassword1, setNewPassword1] = useState();
  const [newPassword2, setNewPassword2] = useState();
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);

  const { isDarkMode } = useContext(DarkModeContext);
  const history = useHistory();

  const setNewPassword = async () => {
    try {
      setLoading(true);
      const response = await fetch("/api/auth/password/reset/confirm/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({ uid, token, newPassword1, newPassword2 }),
      });
      if (response.ok) {
        setSuccess(true);
        setErrors({});
        return;
      }
      if (response.status === 429) {
        history.push("/429/");
      }
      setSuccess(false);
      const data = await response.json();
      setErrors(data);
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };
  const error = errors.uid !== undefined || errors.token !== undefined;
  return (
    <div className="reset-password-container">
      <Segment style={{ width: "400px" }} basic>
        <Header inverted={isDarkMode} textAlign="center" as="h2" color="teal">
          Reset Your Password
        </Header>
        <Form
          inverted={isDarkMode}
          success={success}
          error={error}
          loading={loading}
          onSubmit={setNewPassword}
        >
          <Form.Input
            type="password"
            icon="lock"
            iconPosition="left"
            label="New Password"
            placeholder="New Password"
            value={newPassword1}
            error={errors.newPassword1}
            onChange={(e, { value }) => setNewPassword1(value)}
            required
          />
          <Form.Input
            type="password"
            icon="lock"
            iconPosition="left"
            label="Confirm New Password"
            placeholder="Confirm New Password"
            value={newPassword2}
            error={errors.newPassword2}
            onChange={(e, { value }) => setNewPassword2(value)}
            required
          />
          <Message
            success
            header="Success"
            content="Your password has been reset."
          />
          <Message error header="Error">
            {Object.keys(errors).map((key) => (
              <div key={key}>
                <b>{key}: </b>
                {errors[key]}
              </div>
            ))}
          </Message>

          <Button type="submit">Reset Password</Button>
        </Form>
      </Segment>
    </div>
  );
}
