import Cookies from "js-cookie";
import React, { useContext, useState } from "react";
import { Button, Form, Message } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";

const errorMessage =
  "Some error occured when transferring balance. Please try again later.";

export default function TransferBalance({ title, refreshBalance }) {
  const [receiver, setReceiver] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(null);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const transferBalance = async () => {
    setSuccess(false);
    setErrors({});

    if (!window.confirm("Are you sure?")) return;

    try {
      setLoading(true);
      const response = await fetch("/api/transfer-balance/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({
          receiver,
          amount: parseFloat(amount),
          description,
        }),
      });
      if (response.ok) {
        setSuccess(true);
        setErrors({});
        refreshBalance();
      } else if (response.status === 400) {
        const json = await response.json();
        setErrors(json);
      } else if (response.status === 403) {
        setErrors({ detail: "You are not allowed to transfer balance." });
      } else {
        setErrors({ detail: errorMessage });
      }
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <div className="transfer-balance-form attatched-from">
      <Message
        attached
        color={isDarkMode ? "black" : "grey"}
        header={title || "Transfer Balance"}
        content="Enter the receiver and amount below to transfer balance."
      />
      <Form
        inverted={isDarkMode}
        className="attached segment"
        loading={loading}
        success={success}
        error={errors.detail || errors.nonFieldErrors}
        onSubmit={transferBalance}
      >
        <Form.Input
          placeholder="Enter receiver email"
          onChange={(e, { value }) => setReceiver(value)}
          value={receiver}
          error={errors.receiver}
          type="email"
          required
        />
        <Form.Input
          placeholder="Enter amount"
          onChange={(e, { value }) => setAmount(value)}
          value={amount}
          error={errors.amount}
          type="number"
          min={0.01}
          step=".01"
          inputmode="numeric"
          required
        />
        <Form.TextArea
          placeholder="Enter note"
          onChange={(e, { value }) => setDescription(value)}
          value={description}
          error={errors.description}
          type="text"
        />
        <Message
          success
          header="Balance Transfer Successful"
          content="The balance has been transferred successfully."
        />
        <Message
          error
          header="Error"
          content={errors.detail || errors.nonFieldErrors}
        />
        <Button color="twitter" type="Submmit" disabled={loading}>
          Submit
        </Button>
      </Form>
    </div>
  );
}
