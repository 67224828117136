import { useContext } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";
import "./index.css";
const getSteps = (auctionItem) => {
  // steps are shown in order:
  // 1. Added to Marketplace
  // 2. Password Changed
  // 3. Bids Status
  // 4. Auction Failed/Completed
  // 5. Payment/Product returned
  // 6. Warranty Status
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  const steps = [
    {
      key: "1",
      title: "Added to Marketplace",
      description: `${formatDate(auctionItem.dateCreated)}`,
    },
    {
      key: "2",
      title: "Account Password Changed",
      description: "Password changed by admin",
    },
  ];
  if (auctionItem.totalBids) {
    steps.push({
      key: "3",
      title: "Bids on Product",
      description: `${auctionItem.totalBids} bid(s), Highest bid: €${auctionItem.highestBidAmount}`,
    });
  } else {
    steps.push({
      key: "3",
      title: "Bids Status",
      description: "No bids on product",
    });
  }
  if (auctionItem.status === "SUCCESS") {
    steps.push({
      key: "4",
      title: "Auction Completed",
      description: `${formatDate(auctionItem.dateCompleted)}`,
    });
  } else if (auctionItem.status === "FAILURE") {
    steps.push({
      key: "4",
      title: "Auction Failed",
      description: `Ended on ${formatDate(auctionItem.endDate)}`,
    });
  } else {
    steps.push({
      key: "4",
      title: "Auction In Progress",
      description: `Ends on ${formatDate(auctionItem.endDate)}`,
    });
  }
  if (auctionItem.status === "ONGOING") {
    steps.push({
      key: "5",
      title: "Payment Status",
      description: "Auction in progress",
    });
  } else if (auctionItem.paymentReceived) {
    steps.push({
      key: "5",
      title: "Payment Received",
      description: `Received amount: €${(
        auctionItem.highestBidAmount * 0.9
      ).toFixed(2)}`,
    });
  } else if (auctionItem.productReturned) {
    steps.push({
      key: "5",
      title: "Product Returned",
      description: "Returned to 'Your Purchases'",
    });
  } else {
    steps.push({
      key: "5",
      title: "Payment Status",
      description: "Payment not received yet",
    });
  }
  if (auctionItem.warrantyDate) {
    if (auctionItem.productReturned) {
      steps.push({
        key: "6",
        title: "Warranty Status",
        description: "Warranty ended due to product return",
      });
    } else if (auctionItem.warrantyEnded) {
      steps.push({
        key: "6",
        title: "Warranty Expired",
        description: `Warranty ended on ${formatDate(
          auctionItem.warrantyDate,
        )}`,
      });
    } else {
      steps.push({
        key: "6",
        title: "Warranty Period",
        description: `Warranty ends on ${formatDate(auctionItem.warrantyDate)}`,
      });
    }
  } else {
    steps.push({
      key: "6",
      title: "Warranty Status",
      description: "No warranty",
    });
  }

  return steps;
};

const getCurrentStep = (auctionItem) => {
  // returns [1 - 7]
  // all steps upto current step are completed
  // [1, current-step)
  // 7 = all steps are completed
  if (auctionItem.status === "ONGOING") {
    // always 3
    return 3;
  }
  if (auctionItem.status === "FAILURE") {
    // either 5 or 7
    if (auctionItem.productReturned) {
      return 7;
    }
    return 5;
  }
  if (auctionItem.status === "SUCCESS") {
    // either 5, 6 or 7
    if (auctionItem.warrantyEnded) {
      return 7;
    }
    if (auctionItem.paymentReceived) {
      return 6;
    }
    return 5;
  }
};

const StatusItem = ({ data, completed, active }) => (
  <div className="status-item">
    <div className={`status-item-content ${active || completed ? "" : "dim"}`}>
      <h4>{data.title}</h4>
      <p>{data.description}</p>
    </div>
    <Icon
      size={active ? "small" : ""}
      className={`circle-icon ${
        completed ? "completed" : active ? "active" : ""
      }`}
      name={completed ? "check" : active ? "circle" : ""}
    />
  </div>
);

export const AuctionItemStatusModal = ({ setOpen, auctionItem }) => {
  const steps = getSteps(auctionItem);
  const currentStep = getCurrentStep(auctionItem);
  console.log(auctionItem);
  console.log(steps);
  const { isDarkMode } = useContext(DarkModeContext);

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open
      closeOnDimmerClick={false}
      className={`trade-modal${isDarkMode ? " dark" : ""}`}
      data-theme={isDarkMode ? "dark" : "light"}
      closeIcon
      onClose={handleModalClose}
      onOpen={() => setOpen(true)}
      size={"mini"}
    >
      <Modal.Header>Marketplace Status</Modal.Header>
      <Modal.Content>
        {/* <Header as="h4" inverted={isDarkMode}>
          Product: '{auctionItem.product.username}'
        </Header> */}

        <div className="status-container">
          {steps.map((data, index) => (
            <StatusItem
              key={index}
              data={data}
              completed={index + 1 < currentStep}
              active={index + 1 === currentStep}
            />
          ))}
        </div>
      </Modal.Content>

      <Modal.Actions className="trade-modal-actions">
        <Button color="red" onClick={handleModalClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
