import { useContext, useState } from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import DarkModeContext from "../../contexts/DarkModeContext";
import { useRouteMatch } from "react-router-dom";
import Error from "../Errors";
import Cookies from "js-cookie";

export const ProductHideModal = ({ setOpen, product, refreshTable }) => {
  const { isDarkMode } = useContext(DarkModeContext);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const match = useRouteMatch(
    "/my-account/profile/your-purchases/hidden-accounts/",
  );
  const isHiddenAccounts = match ? true : false;
  const handleModalClose = () => {
    setOpen(false);
    success && refreshTable();
  };

  const handleHide = async () => {
    try {
      setSuccess(false);
      setErrors({});
      setLoading(true);
      const url = `/api/products/${product.username}/${
        isHiddenAccounts ? "unhide" : "hide"
      }/`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
      });
      const data = await response.json();
      if (response.ok) {
        setSuccess(true);
        setErrors({});
      } else {
        setErrors(data);
        console.log(data.error);
      }
    } catch (reason) {
      setErrors({
        error: "Error connecting to server. Please try again later.",
      });
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open
      closeOnDimmerClick={false}
      className={`trade-modal${isDarkMode ? " dark" : ""}`}
      data-theme={isDarkMode ? "dark" : "light"}
      closeIcon
      onClose={handleModalClose}
      onOpen={() => setOpen(true)}
      size={"mini"}
    >
      <Modal.Content>
        <div>
          <Header inverted={isDarkMode}>
            Are you sure you want to {isHiddenAccounts ? "unhide" : "hide"} this
            account?
          </Header>
          <Error errors={errors} />
        </div>
      </Modal.Content>

      <Modal.Actions className="trade-modal-actions">
        {!success ? (
          <>
            <Button onClick={handleModalClose} disabled={loading}>
              Close
            </Button>
            <Button
              color="twitter"
              onClick={handleHide}
              disabled={loading}
              loading={loading}
            >
              Continue
            </Button>
          </>
        ) : (
          <Button
            color="twitter"
            onClick={handleModalClose}
            icon
            labelPosition="right"
          >
            Done
            <Icon name="check" />
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
};
