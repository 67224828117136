/* eslint-disable jsx-a11y/iframe-has-title */
import {
  faClock,
  faHandHoldingDollar,
  faShieldAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FaFacebook, FaTiktok, FaTwitter, FaDiscord } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import { Icon } from "semantic-ui-react";
import {
  discordLink,
  discordWidgetId,
  displayName,
  facebookLink,
  tiktokLink,
  twitterLink,
} from "../../constants";
import DarkModeContext from "../../contexts/DarkModeContext";
import HomepageBanner from "./HomepageBanner";
import ReviewItem from "./ReviewItem";
import ServicesProvided from "../../assets/service-provided.png";
import FAQSectionImg from "../../assets/faq-section.png";
import FAQ from "./Faq";
import "./index.css";
import useWindowDimensions from "../../hooks/useWIndowDimensions";

const services = require("../../json/services.json");
const reviews = require("../../json/reviews.json");

const icons = {
  faHandHoldingDollar: faHandHoldingDollar,
  faClock: faClock,
  faShieldAlt: faShieldAlt,
};

export default function SmurfSkinsHomepage() {
  const { isDarkMode } = useContext(DarkModeContext);
  const windowWidth = useWindowDimensions().width;
  useEffect(
    () => (document.title = `${displayName} | Best place to buy LoL accounts`),
  );

  return (
    <div className="homepage-navigation">
      <HomepageBanner />
      <div className="homepage-container">
        <div className="vision-container">
          <h1 className="container-title vision-title">Our Vision</h1>
          <p className="vision-details">
            We at {displayName} want to deliver the best and most superior
            service for our loyal customers. Buy your own Skin Smurf right now
            and enjoy our many benefits.
          </p>
        </div>

        <div className="service-provided-wrapper">
          <div className="services-provided-container">
            <h1 className="container-title">What We Provide</h1>
            <div className="service-provided-group">
              {services.map((service, index) => {
                return (
                  <div className="service-provided-item" key={index}>
                    <FontAwesomeIcon
                      className="service-provided-icon"
                      icon={icons[service.image]}
                      size="2x"
                      style={{
                        color: isDarkMode ? "#14597b" : "#F8F8F9",
                        backgroundColor: isDarkMode ? "#072c3f" : "#C5C5C5",
                      }}
                    />
                    <h4 className="service-provided-title">{service.title}</h4>
                    <p className="service-provided-desc">
                      {service.description}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="service-provided__image">
            <img src={ServicesProvided} alt="join smurfsksin" />
          </div>
        </div>

        <div className="join-discord-container">
          <div className="discord-links">
            <h1 className="container-title">Keep In Touch</h1>
            <div className="discord-links__text">
              <p>
                Stay in touch with us on social media! Follow us for the latest
                updates, discounts, giveaways, support, and hassle-free warranty
                refunds. Don't miss out on anything – Join us now!
              </p>
              <div className="social-links-div">
                <h3>Social Links</h3>
                <div className="social-links">
                  {[
                    {
                      icon: (
                        <FaDiscord
                          className={
                            isDarkMode
                              ? `social-icon-dark`
                              : `social-icon-light`
                          }
                        />
                      ),
                      link: discordLink,
                    },
                    {
                      icon: (
                        <FaTwitter
                          className={
                            isDarkMode
                              ? `social-icon-dark`
                              : `social-icon-light`
                          }
                        />
                      ),
                      link: twitterLink,
                    },
                    {
                      icon: (
                        <FaFacebook
                          className={
                            isDarkMode
                              ? `social-icon-dark`
                              : `social-icon-light`
                          }
                        />
                      ),
                      link: facebookLink,
                    },
                    {
                      icon: (
                        <FaTiktok
                          className={
                            isDarkMode
                              ? `social-icon-dark`
                              : `social-icon-light`
                          }
                        />
                      ),
                      link: tiktokLink,
                    },
                  ].map((social, index) => (
                    <a
                      key={index}
                      href={social.link}
                      target="_blank"
                      rel="noreferrer"
                      className={
                        isDarkMode
                          ? "social-link-icon-dark"
                          : "social-link-icon-light"
                      }
                    >
                      {social.icon}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div>
            <iframe
              title="smurfskin discord"
              width={
                windowWidth < 768 ? "100%" : windowWidth < 1150 ? "300" : "450"
              }
              src={`https://discord.com/widget?id=${discordWidgetId}&theme=dark`}
              height="300"
              allowtransparency="true"
              frameBorder="0"
              sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            ></iframe>
          </div>
        </div>

        <div className="review-container-wrapper">
          <h1 className="container-title review-title">
            Happy Customer Reviews
          </h1>
          <div
            className="reviews-carousel"
            style={{
              backgroundColor: isDarkMode ? "#052535" : "#F8F8F9",
              color: isDarkMode ? "#F8F8F9" : "#0c2b3b",
            }}
          >
            <Carousel
              emulateTouch
              infiniteLoop
              showStatus={false}
              showThumbs={false}
              showArrows={false}
              autoPlay
              renderIndicator={(clickHandler, isSelected) => (
                <Icon
                  size="large"
                  name="window minimize outline"
                  onClick={clickHandler}
                  style={{ color: isSelected ? "grey" : "#0c2b3b" }}
                  className="carousel-icon"
                />
              )}
            >
              {reviews.map((review, index) => (
                <ReviewItem key={index} review={review} />
              ))}
            </Carousel>
          </div>
        </div>

        <div className="faq-container">
          <h1 className="container-title">FAQ</h1>
          <div className="faq-group-container">
            <FAQ />
            <div className="faq-container__image">
              <img src={FAQSectionImg} alt="Faq smurfsksin" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
