import { useContext } from "react";
import { Table } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";

export default function YourPaymentsTable({ paymentItems }) {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <Table inverted={isDarkMode}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>ID</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Payment Method</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {paymentItems.map((paymentItem) => {
          return (
            <Table.Row key={paymentItem.id}>
              <Table.Cell>{paymentItem.id}</Table.Cell>
              <Table.Cell>
                {new Date(paymentItem.dateCreated).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
              </Table.Cell>
              <Table.Cell>{paymentItem.paymentMethod}</Table.Cell>
              <Table.Cell>
                {parseFloat(paymentItem.amount).toLocaleString(undefined, {
                  style: "currency",
                  currency: "EUR",
                })}
              </Table.Cell>
              <Table.Cell>
                {paymentItem.isVerified
                  ? "Payment successful."
                  : paymentItem.error
                  ? paymentItem.error
                  : paymentItem.retries <= 0
                  ? "Payment processing."
                  : "Payment verification failed."}
              </Table.Cell>
              <Table.Cell>
                {`Payment for Order #${paymentItem.order}`}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
