import { useHistory, useLocation } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { getFilter } from "../utils";
import CustomDropDown from "./CustomDropdown";
import InfoPopup from "./InfoPopup";

const ranks = require("../json/ranks.json");

const useQuery = () => new URLSearchParams(useLocation().search);

export default function RankSearch({ onChange, path }) {
  const query = useQuery();
  const history = useHistory();

  const handleRankChange = (rank) => {
    if (rank) query.set("rank", rank);
    else query.delete("rank");
    history.push({ pathname: path, search: query.toString() });
  };
  const handleDivisionChange = (division) => {
    if (division) query.set("division", division);
    else query.delete("division");
    history.push({ pathname: path, search: query.toString() });
  };

  return (
    <>
      <Menu.Item>
        <Menu.Header>Rank</Menu.Header>
        <div className="search-item">
          <CustomDropDown
            selection
            clearable
            value={query.get("rank")}
            options={Object.values(ranks.ranks)}
            placeholder="Choose a rank"
            onChange={(e, { value }) => {
              handleRankChange(value);
              onChange(getFilter(query));
            }}
          />
          <InfoPopup description="Choose rank" />
        </div>
      </Menu.Item>
      <Menu.Item>
        <Menu.Header>Division</Menu.Header>
        <div className="search-item">
          <CustomDropDown
            disabled={
              !query.get("rank") ||
              ["MA", "GR", "CH"].includes(query.get("rank"))
            }
            selection
            clearable
            value={query.get("division") && parseInt(query.get("division"))}
            options={ranks.divisions}
            placeholder="Choose rank division"
            onChange={(e, { value }) => {
              handleDivisionChange(value);
              onChange(getFilter(query));
            }}
          />
          <InfoPopup description="Choose division" />
        </div>
      </Menu.Item>
    </>
  );
}
