import React, { useContext, useEffect, useState } from "react";
import { Icon, Label, LabelGroup, Popup, Segment } from "semantic-ui-react";
import InfoPopup from "../../components/InfoPopup";
import ShowSkinsPopup from "../../components/ShowSkinsPopup";
import { rankedValues } from "../../constants";
import DarkModeContext from "../../contexts/DarkModeContext";
import {
  getRegion,
  calculateDaysDifference,
  calculateTimeRemaining,
} from "../../utils";
import InstaBuyPopup from "./InstaBuyPopup";
import PlaceBidPopup from "./PlaceBidPopup";

export default function AuctionProductCard({
  product,
  userId,
  isLogged,
  isTradeCard = false,
}) {
  const { isDarkMode } = useContext(DarkModeContext);
  const [isInstaBuyPlaced, setIsInstaBuyPlaced] = useState(false);
  const [isBidPlaced, setIsBidPlaced] = useState(product.userBidAmount != null);
  const [bidAmount, setBidAmount] = useState(product.userBidAmount);
  const [isRemarksHovered, setIsRemarksHovered] = useState(false);

  const renderIconPopups = () => (
    <div className="product-card-icons">
      {product.product.tags?.map((tag, index) => (
        <Popup
          key={index}
          trigger={
            tag.img ? (
              <img src={tag.img} className="product-icon" alt="icon" />
            ) : (
              <Icon
                className="product-icon"
                name={tag.icon || "tag"}
                style={{ color: tag.color || "#fdde81" }}
                size="large"
              />
            )
          }
          content={tag.description}
        />
      ))}
    </div>
  );
  const renderLabelTags = () => (
    <LabelGroup className="product-card-labels">
      {product.product.label_tags?.map((tag, index) => {
        if (tag.description) {
          return (
            <Popup
              key={index}
              trigger={
                <Label key={index} className={tag.color}>
                  {tag.icon && <Icon name={tag.icon} />}
                  {tag.label}
                </Label>
              }
              content={tag.description}
            />
          );
        }

        return (
          <Label key={index} className={tag.color}>
            {tag.icon && <Icon name={tag.icon} />}
            {tag.label}
          </Label>
        );
      })}
    </LabelGroup>
  );

  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(product.endDate),
  );

  const warranty = calculateDaysDifference(
    product.warrantyDate,
    product.endDate,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(product.endDate));
    }, 60000); // Update every minute

    return () => {
      clearInterval(interval);
    };
  }, [product.endDate]);

  return (
    <Segment.Group className={`product-card ${isDarkMode ? "dark-mode" : ""}`}>
      <Segment
        basic
        inverted={isDarkMode}
        className="product-card-image-wrapper "
      >
        <div className="product-card-icons-wrapper">
          {renderLabelTags()}
          {!isTradeCard && renderIconPopups()}
        </div>
        <div
          className="product-card-image"
          style={{
            backgroundImage: `linear-gradient(180deg, var(--gradient) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 80%, var(--gradient) 100%), url(${product.product.thumbnail})`,
          }}
        ></div>
      </Segment>
      <Segment inverted={isDarkMode} className="product-details">
        <div className="product-header">
          <div className="product-description">
            {isTradeCard && <div>Username: {product.username}</div>}
            <div>Region: {getRegion(product.product.region)}</div>
            <div>Level: {product.discreteLevel || 30}+</div>{" "}
            {/*no discretelevel in data */}
            <div className="be">
              Blue Essence:
              {product.product.blueEssence <= 0
                ? " <10,000"
                : " " + product.product.blueEssence.toLocaleString() + "+"}
              {product.product.isHandleveled ? (
                <InfoPopup description="This account contains champion capsules." />
              ) : null}
            </div>
            <div>
              Orange Essence: {product.product.orangeEssence.toLocaleString()}
            </div>
            {rankedValues.includes(product.product.rank) && (
              <div>
                Ranked Wins/Losses: {product.product.soloWins || " - "}/
                {product.product.soloLosses || " - "}
              </div>
            )}
            {product.remarks && (
              <Popup
                open={product.remarks.length > 18 && isRemarksHovered}
                trigger={
                  <div>
                    Note from seller:{" "}
                    {product.remarks.length > 18 ? (
                      <span
                        onMouseEnter={() => setIsRemarksHovered(true)}
                        onMouseLeave={() => setIsRemarksHovered(false)}
                      >
                        {product.remarks.slice(0, 16)}...
                      </span>
                    ) : (
                      product.remarks
                    )}
                  </div>
                }
                content={product.remarks}
                position="top right"
              />
            )}
            <div>
              <Icon name={"clock"} />
              Ends in: {timeRemaining}
            </div>
            <div>
              Warranty:{" "}
              {product.warrantyDate
                ? `${warranty} ${warranty === 1 ? "day" : "days"}`
                : "None"}
            </div>
            <div>
              Highest Bid:{" "}
              {bidAmount && bidAmount > product.highestBidAmount
                ? `€${bidAmount}`
                : product.highestBidAmount
                ? `€${product.highestBidAmount}`
                : "-"}
            </div>
            {isLogged && (
              <div>Your Bid: {bidAmount ? `€${bidAmount}` : "-"}</div>
            )}
            <div>
              Bids Placed:{" "}
              {isBidPlaced && !product.userBidAmount
                ? product.totalBids + 1
                : product.totalBids}
            </div>
          </div>
        </div>
      </Segment>
      <Segment className="product-card-buttons" inverted={isDarkMode} basic>
        <ShowSkinsPopup
          skins={product.product.skins}
          permanent_skins={product.product.permanentSkins}
          owned_skins={product.product.ownedSkins}
          relevantSkins={product.product.relevantSkins}
        />
      </Segment>
      <Segment className="product-card-buttons" inverted={isDarkMode} basic>
        {!isTradeCard && (
          <InstaBuyPopup
            product={product}
            isInstaBuyPlaced={isInstaBuyPlaced}
            setIsInstaBuyPlaced={setIsInstaBuyPlaced}
          />
        )}
      </Segment>
      <Segment className="product-card-buttons" inverted={isDarkMode} basic>
        {!isTradeCard && (
          <PlaceBidPopup
            product={product}
            isBidPlaced={isBidPlaced}
            setIsBidPlaced={setIsBidPlaced}
            isInstaBuyPlaced={isInstaBuyPlaced}
            setBidAmount={setBidAmount}
          />
        )}
      </Segment>
    </Segment.Group>
  );
}
