import React from "react";
import { useHistory } from "react-router-dom";
import { Icon, Label, Menu } from "semantic-ui-react";
import { mythicAccountsLink } from "../../constants";
const discordLink = process.env.REACT_APP_DISCORD_LINK;

export default function HamMenu({
  getActive,
  cartItems,
  isDarkMode,
  toggleDarkMode,
  allowDarkMode,
  displayLogoTitle,
  setMenu,
  isAuctionEnabled,
}) {
  let history = useHistory();
  return (
    <Menu inverted className="nav">
      <Menu.Menu className="ham-menu">
        <Menu.Item
          active={getActive() === "cart"}
          onClick={() => {
            history.push("/my-cart/");
            setMenu((prev) => !prev);
          }}
        >
          <div className="mobile-nav-cart-icon">
            <Icon name="shop" /> My Cart
            {cartItems.length >= 1 && (
              <Label
                floating
                size="mini"
                circular
                color="teal"
                className="mobile-nav-cart-label"
              >
                {cartItems.length}
              </Label>
            )}
          </div>
        </Menu.Item>
        <Menu.Item
          name="Home"
          active={getActive() === "home"}
          onClick={() => {
            history.push("/");
            setMenu((prev) => !prev);
          }}
        />
        <Menu.Item
          name="Buy Account"
          active={getActive() === "buy-account"}
          onClick={() => {
            history.push("/buy-account/");
            setMenu((prev) => !prev);
          }}
        />
        {isAuctionEnabled && (
          <Menu.Item
            active={getActive() === "marketplace"}
            onClick={() => {
              history.push("/marketplace/");
              setMenu((prev) => !prev);
            }}
          >
            <div className="mobile-nav-cart-icon">
              Marketplace
              <Label
                floating
                size="mini"
                color="teal"
                className="mobile-marketplace-label"
              >
                Beta
              </Label>
            </div>
          </Menu.Item>
        )}
        <Menu.Item
          name="My Account"
          active={getActive() === "my-account"}
          onClick={() => {
            history.push({
              pathname: "/my-account/profile/your-purchases/",
              search: "?page=1&limit=5",
            });
            setMenu((prev) => !prev);
          }}
        />
        {!displayLogoTitle && (
          <Menu.Item
            name="FAQ"
            active={getActive() === "faq"}
            onClick={() => {
              history.push("/faq/");
              setMenu((prev) => !prev);
            }}
          />
        )}
        {discordLink ? (
          <Menu.Item
            name="Join Discord"
            active={false}
            onClick={() => {
              window.open(discordLink, "_blank");
              setMenu((prev) => !prev);
            }}
          />
        ) : null}
        {allowDarkMode && (
          <Menu.Item
            onClick={() => {
              toggleDarkMode(!isDarkMode);
              setMenu((prev) => !prev);
            }}
          >
            <Icon name="moon" color={isDarkMode ? "teal" : "white"} />
          </Menu.Item>
        )}
      </Menu.Menu>
    </Menu>
  );
}
