import React, { useContext, useState } from "react";
import { Button, Form, Message } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";
import Cookies from "js-cookie";
import { leagueShopMode } from "../constants";
const errorMessage =
  "Cross site balance transfer is unavailable at the moment. Please try again later.";

export default function TransferBalanceCrossSite({ title, refreshBalance }) {
  const [amount, setAmount] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [couponAmount, setCouponAmount] = useState(0);
  const [redeemUrl, setRedeemUrl] = useState("#");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const isSmurfSkins = leagueShopMode === "smurfskins";

  const transferBalanceCrossSite = async () => {
    setSuccess(false);
    setErrors({});

    if (!window.confirm("Are you sure?")) return;

    try {
      setLoading(true);
      const response = await fetch("/api/transfer-balance-cross-site/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
        },
        body: JSON.stringify({ amount: parseFloat(amount) }),
      });
      if (response.ok) {
        const json = await response.json();
        setSuccess(true);
        setErrors({});
        refreshBalance();
        setCouponCode(json.code);
        setCouponAmount(json.amount);
        setRedeemUrl(json.redeemUrl);
      } else if (response.status === 400) {
        const json = await response.json();
        setErrors(json);
      } else {
        setErrors({ detail: errorMessage });
      }
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <div className="transfer-balance-form attatched-from">
      <Message
        attached
        color={isDarkMode ? "black" : "grey"}
        header={title || "Cross Site Balance Transfer"}
        content={`Enter the amount to transfer to user in ${
          isSmurfSkins ? "Lolaccshop" : "Smurfskins"
        }. It can be redeemed as coupon.`}
      />
      <Form
        inverted={isDarkMode}
        className="attached segment"
        loading={loading}
        success={success}
        error={Boolean(errors.detail || errors.nonFieldErrors)}
        onSubmit={transferBalanceCrossSite}
      >
        <Form.Input
          placeholder="Enter amount"
          onChange={(e, { value }) => setAmount(value)}
          value={amount}
          error={errors.amount}
          type="number"
          min={0.01}
          step=".01"
          inputMode="numeric"
          required
        />
        <Message success>
          <Message.Header>Balance Transfer Successful</Message.Header>
          <Message.Content>
            The request is successful. Use the coupon to redeem the balance.{" "}
            <br />
            Amount: {<b>€ {couponAmount}</b>} <br />
            Coupon Code: {<b>{couponCode}</b>} <br />
            {<b>Keep this coupon safe. You will only see this once.</b>} <br />
            Follow this{" "}
            <a href={redeemUrl} target="_blank" rel="noopener noreferrer">
              {" "}
              <b>link</b>{" "}
            </a>{" "}
            to redeem the balance.
          </Message.Content>
        </Message>
        <Message
          error
          header="Error"
          content={errors.detail || errors.nonFieldErrors}
        />
        <Button color="twitter" type="Submit" disabled={loading}>
          Submit
        </Button>
      </Form>
    </div>
  );
}
