import Cookies from "js-cookie";
import React, { useContext, useState } from "react";

import { Button, Form, Icon, Input, Modal, Popup } from "semantic-ui-react";
import Error from "../../components/Errors";
import ValidationError from "../../components/Errors/ValidationError";
import CartContext from "../../contexts/CartContext";
import DarkModeContext from "../../contexts/DarkModeContext";
import InfoPopup from "../../components/InfoPopup";

export default function PlaceBidPopup({
  product,
  isBidPlaced,
  setIsBidPlaced,
  isInstaBuyPlaced,
  setBidAmount,
}) {
  // NOTE: This value is differnet than product.highestBidAmount
  // because a bidder not only has to bid higer than minBidAmount
  // but also must bid higher than the highest bid amount
  const minAllowedBidAmount = product.highestBidAmount
    ? (product.highestBidAmount + 0.01).toFixed(2)
    : product.minimumBidAmount;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [forbidden, setForbidden] = useState(false);
  const { refreshCart } = useContext(CartContext);
  const [amount, setAmount] = useState(minAllowedBidAmount);
  const [isModalOpen, setIsModelOpen] = useState(false);
  const { isDarkMode } = useContext(DarkModeContext);
  const [isHovered, setIsHovered] = useState(false);

  const handlePlaceBid = async () => {
    setLoading(true);
    const body = {
      product: product.product.id,
      amount: amount,
      isInstaBuy: false,
    };
    try {
      setErrors({});
      setLoading(true);
      const response = await fetch(
        `/api/marketplace/bids/${product.product.id}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Cookies.get("csrftoken"),
          },
          body: JSON.stringify(body),
        },
      );
      if (response.status === 403) {
        setForbidden(true);
        setIsModelOpen(false);
        return;
      }

      if (!response.ok) {
        const error = await response.json();
        setErrors(error);
        setLoading(false);
        return;
      }
      if (response.status === 201) {
        refreshCart();
        setIsModelOpen(false);
        setIsBidPlaced(true);
        setBidAmount(amount);
      }
    } catch (error) {
      setErrors({
        error: error.detail,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditChange = (e) => {
    setAmount(e.target.value);
  };

  const handleModalClose = () => {
    setIsModelOpen(false);
    setErrors({});
    setAmount(minAllowedBidAmount);
  };

  const handleProceed = async () => {
    try {
      setLoading(true);
      const response = await fetch("/api/auth/user/");
      if (response.status === 403) {
        setForbidden(true);
        return;
      }
      if (response.ok) {
        setIsModelOpen(true);
      }
    } catch (reason) {
      console.log(reason);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Popup
        on="click"
        open={
          forbidden ||
          (isHovered &&
            (!product.minimumBidAmount || isBidPlaced || product.isUserOwner))
        }
        trigger={
          <div
            style={{ width: "100%" }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Button
              fluid
              disabled={
                loading ||
                !product.minimumBidAmount ||
                isInstaBuyPlaced ||
                isBidPlaced ||
                product.isUserOwner
              }
              loading={loading}
              color="twitter"
              onClick={() => handleProceed()}
            >
              {isBidPlaced || isInstaBuyPlaced ? (
                <>
                  <Icon name={"check circle"} />
                  Bid Placed
                </>
              ) : (
                <>
                  <Icon name={"hand paper"} />
                  Place Bid
                  {product.minimumBidAmount &&
                    ` (min. €${minAllowedBidAmount})`}
                </>
              )}
            </Button>
          </div>
        }
        onClose={() => setForbidden(false)}
      >
        <Popup.Content>
          {isHovered &&
            (!product.minimumBidAmount ? (
              <>
                <p>The owner has disabled placing bids on this account.</p>
              </>
            ) : product.isUserOwner ? (
              <>
                <p>Cannot bid on own product.</p>
              </>
            ) : isBidPlaced ? (
              <>
                <p>You have already placed a bid on this account.</p>
              </>
            ) : (
              <></>
            ))}
          {forbidden && (
            <>
              <p>Login to place bid.</p>
              <Button.Group>
                <Button
                  color="teal"
                  onClick={() => {
                    window.open("/my-account/");
                    setForbidden(false);
                  }}
                >
                  Login
                </Button>
                <Button.Or />
                <Button
                  onClick={() => {
                    window.open("/my-account/");
                    setForbidden(false);
                  }}
                >
                  Register
                </Button>
              </Button.Group>
            </>
          )}
        </Popup.Content>
      </Popup>
      {isModalOpen && (
        <Modal
          open
          closeOnDimmerClick={false}
          className={`trade-modal${isDarkMode ? " dark" : ""}`}
          data-theme={isDarkMode ? "dark" : "light"}
          closeIcon
          onClose={handleModalClose}
          onOpen={() => setIsModelOpen(true)}
          size={"mini"}
        >
          <Modal.Header className="search-item">
            Place Bid
            <InfoPopup
              description={
                "Your balance will be used when bidding. If you do not win the auction or someone over bids you, the balance will be refunded."
              }
            />
          </Modal.Header>
          <Modal.Content>
            <div style={{ width: "100%" }}>
              <Form inverted={isDarkMode}>
                <Form.Field style={{ width: "100%" }}>
                  <label>Bid Amount</label>
                  {amount < Number(product.minimumBidAmount) && (
                    <ValidationError error={"Amount less than minimum bid"} />
                  )}
                  {product.instaBuyAmount &&
                    amount > Number(product.instaBuyAmount) && (
                      <ValidationError
                        error={"Amount greater than instant buy amount"}
                      />
                    )}
                  <Input
                    fluid
                    placeholder={"Bid Amount"}
                    name="amount"
                    type={"number"}
                    onChange={handleEditChange}
                    value={amount}
                    required
                  />
                </Form.Field>
              </Form>
              <Error errors={errors} />
            </div>
          </Modal.Content>

          <Modal.Actions className="trade-modal-actions">
            <Button
              color="twitter"
              onClick={handlePlaceBid}
              disabled={
                loading ||
                amount < Number(product.minimumBidAmount) ||
                (product.instaBuyAmount &&
                  amount > Number(product.instaBuyAmount))
              }
              loading={loading}
            >
              Place Bid
            </Button>
            <Button color="red" onClick={handleModalClose} disabled={loading}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  );
}
