import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import AuthLoader from "./AuthLoader";

export default function RedirectOnAuthSuccess({ to, children }) {
  const [loading, setLoading] = useState(true);
  const history = useRef(useHistory());

  const fetchUser = async (signal, to) => {
    try {
      setLoading(true);
      const response = await fetch("/api/auth/user/", { signal });
      if (response.status === 429) {
        history.current.push("/429/");
      }
      if (response.ok) {
        history.current.push(to);
      } else {
        setLoading(false);
      }
    } catch (reason) {
      console.log(reason);
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    fetchUser(controller.signal, to);
    return () => controller.abort();
  }, [to]);
  return loading ? <AuthLoader /> : children;
}

RedirectOnAuthSuccess.defaultProps = {
  to: "/my-account/profile/your-purchases/?page=1&limit=5",
};
