import { Icon, Popup } from "semantic-ui-react";

export default function InfoPopup({ description }) {
  return (
    <div className="info-popup-wrapper">
      <Popup
        trigger={
          <Icon name="question circle outline" className="info-popup-icon" />
        }
        content={description}
        wide
      />
    </div>
  );
}
