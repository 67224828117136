import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import { displayName, leagueShopMode } from "../constants";
import PriceContext from "../contexts/PricesContext";
import SkinContext from "../contexts/SkinContext";
import {
  getFilter,
  getIsOldStock,
  getLastPlayedDateObject,
  getPrice,
  getProductTags,
  getThumbnail,
  rankToValue,
} from "../utils";
import NoMythicSearchResults from "./NoMythicSearchResult";
import NoSearchResults from "./NoSearchResults";
import ProductCard from "./ProductCard";
import ProductCardPlaceholder from "./ProductCardPlaceholder";
import Sidebar from "./Sidebar";

const useQuery = () => new URLSearchParams(useLocation().search);

export default function BuyAccountPage() {
  const { skinShards } = useContext(SkinContext);
  const [allProducts, setAllProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(true);
  const query = useQuery();
  const [filter, setFilter] = useState(getFilter(query));

  const { prices } = useContext(PriceContext);
  const showResults = useCallback(
    (allProducts, skinShards, filter) => {
      let output = [...allProducts];
      let interestedSkins = [];

      const mythicSkins = skinShards.reduce((filtered, skin) => {
        if (skin.tier === "MYTHIC") filtered.push(skin.id);
        return filtered;
      }, []);

      // Filter out mythic accounts if filter empty
      const isEmptyFilter = Object.entries(filter).every((value) => {
        return !value[1] || value[1]?.length === 0;
      });
      if (isEmptyFilter) {
        output =
          output.length > 0
            ? output.reduce((filtered, o) => {
                const skins = o[4].concat(o[5], o[6]);
                if (skins.some((skin) => mythicSkins.includes(skin))) {
                  return filtered;
                }
                filtered.push(o);
                return filtered;
              }, [])
            : output;
      }

      // only show premium accounts when premium = true
      if (filter.premium) output = output.filter((o) => o[14] === 1);

      //only show old stock accounts when old-stock = true
      if (filter.accountAge === "old-stock")
        output = output.filter((o) => {
          const dateLastPlayed = getLastPlayedDateObject(o[15]);
          return getIsOldStock(dateLastPlayed, prices);
        });
      else if (filter.accountAge === "new-stock")
        output = output.filter((o) => {
          const dateLastPlayed = getLastPlayedDateObject(o[15]);
          return !getIsOldStock(dateLastPlayed, prices);
        });

      // filter account types
      let accountType = filter.accountType || "any";
      if (accountType) {
        if (accountType === "unranked") {
          output = output.filter(
            (o) => o[8] !== 1 && ["UN", "0"].includes(o[9]),
          );
        } else if (accountType === "handleveled") {
          output = output.filter((o) => o[8] === 1);
        } else if (accountType === "ranked") {
          if (filter.rank) {
            output = output.filter((o) => {
              return (
                o[9] === filter.rank &&
                (filter.division ? o[10] === parseInt(filter.division) : true)
              );
            });
          } else {
            output = output.filter((o) => {
              return o[8] !== 1 && !["UN", "0", ""].includes(o[9]);
            });
          }
        }
      }
      if (filter.flashKey) {
        output = output.filter((o) => o[11] === filter.flashKey);
      }

      if (filter.region && filter.region.length > 0)
        output = output.filter((o) => filter.region.includes(o[1]));
      if (filter.blueEssence)
        output = output.filter((o) => o[2] >= filter.blueEssence);
      if (filter.oeStart || filter.oeEnd)
        output = output.filter((o) => {
          let isFiltered = true;
          if (filter.oeStart) isFiltered = isFiltered && o[3] >= filter.oeStart;
          if (filter.oeEnd) isFiltered = isFiltered && o[3] <= filter.oeEnd;
          return isFiltered;
        });
      if (filter.skinShard && filter.skinShard.length > 0) {
        interestedSkins = interestedSkins.concat(
          filter.skinShard.map((skin) => parseInt(skin)),
        );
        output = output.filter((o) => {
          let allSkins = o[4].concat(o[5], o[6]);
          return filter.skinShard.every((skin) =>
            allSkins.includes(parseInt(skin)),
          );
        });
      }

      output.sort((a, b) => {
        const isOldStockA = getIsOldStock(
          getLastPlayedDateObject(a[15]),
          prices,
        );
        const isOldStockB = getIsOldStock(
          getLastPlayedDateObject(b[15]),
          prices,
        );
        return a[8] - b[8] || a[14] - b[14] || isOldStockA - isOldStockB;
      });

      if (filter.champion && filter.champion.length > 0) {
        const championSkinIds = skinShards
          .filter((s) => filter.champion.includes(s.description))
          .map((s) => s.id);
        interestedSkins = [...interestedSkins, ...championSkinIds];
        output = output.filter((o) => {
          const skins = o[4].concat(o[5], o[6]);
          const count = skins.filter((skin) =>
            championSkinIds.includes(skin),
          ).length;
          return count >= 1;
        });
        output.sort((a, b) => {
          const skinsA = a[4].concat(a[5], a[6]);
          const skinsB = b[4].concat(b[5], b[6]);
          const matchedSkinsA = skinsA.filter((v) =>
            championSkinIds.includes(v),
          ).length;
          const matchedSkinsB = skinsB.filter((v) =>
            championSkinIds.includes(v),
          ).length;
          return matchedSkinsB - matchedSkinsA;
        });
      }

      if (filter.lane) {
        const laneSkinIds = skinShards
          .filter((s) =>
            filter.lane.toLowerCase().includes(s.lane?.toLowerCase()),
          )
          .map((s) => s.id);
        interestedSkins = [...interestedSkins, ...laneSkinIds];

        output =
          output.length > 0
            ? output.reduce((filtered, o) => {
                const skins = o[4].concat(o[5], o[6]);
                let tempSkins = skins.filter((skin) =>
                  laneSkinIds.includes(skin),
                );
                if (tempSkins.length > 0) {
                  o["laneSkinCount"] = tempSkins.length;
                  filtered.push(o);
                }
                return filtered;
              }, [])
            : output;

        output.sort((a, b) => b.laneSkinCount - a.laneSkinCount);
      }

      if (filter.rankOrder) {
        if (filter.rankOrder === "LowToHigh") {
          output.sort((a, b) => {
            const rankA = a[9] + a[10];
            const rankB = b[9] + b[10];
            const valueA = rankToValue(rankA);
            const valueB = rankToValue(rankB);
            if (valueA < valueB) {
              return 1;
            } else if (valueA > valueB) {
              return -1;
            }
            return 0;
          });
        }

        if (filter.rankOrder === "HighToLow") {
          output.sort((a, b) => {
            const rankA = a[9] + a[10];
            const rankB = b[9] + b[10];
            const valueA = rankToValue(rankA);
            const valueB = rankToValue(rankB);
            if (valueA < valueB) {
              return -1;
            } else if (valueA > valueB) {
              return 1;
            }
            return 0;
          });
        }
      }

      if (filter.role) {
        const roleSkinIds = skinShards
          .filter((s) =>
            s.role?.toLowerCase().includes(filter.role.toLowerCase()),
          )
          .map((s) => s.id);
        interestedSkins = [...interestedSkins, ...roleSkinIds];

        output =
          output.length > 0
            ? output.reduce((filtered, o) => {
                const skins = o[4].concat(o[5], o[6]);
                let tempSkins = skins.filter((skin) =>
                  roleSkinIds.includes(skin),
                );
                if (tempSkins.length > 0) {
                  o["roleSkinCount"] = tempSkins.length;
                  filtered.push(o);
                }
                return filtered;
              }, [])
            : output;
        output.sort((a, b) => b.roleSkinCount - a.roleSkinCount);
      }

      if (filter.accountTier) {
        const accountSkinIds = skinShards
          .filter((s) => s.tier === filter.accountTier)
          .map((s) => s.id);
        interestedSkins = [...interestedSkins, ...accountSkinIds];
        output =
          output.length > 0
            ? output.reduce((filtered, o) => {
                const skins = o[4].concat(o[5], o[6]);
                const tempSkins = skins.filter((skin) =>
                  accountSkinIds.includes(skin),
                );
                if (tempSkins.length > 0) {
                  o["accountSkinCount"] = tempSkins.length;
                  filtered.push(o);
                }
                return filtered;
              }, [])
            : output;
      }

      // append price at array's end
      output = output.map((o) => {
        const [
          ,
          ,
          discreteBlueEssence,
          ,
          skins,
          permanentSkins,
          ownedSkins,
          ,
          isHandleveled,
          rank,
          division,
          ,
          ,
          ,
          isPremium,
          date,
        ] = o;
        const dateLastPlayed = getLastPlayedDateObject(date);
        const isOldStock = getIsOldStock(dateLastPlayed, prices);
        const productSkins = [...skins, ...permanentSkins, ...ownedSkins];
        const mythicCount = productSkins.filter((skin) =>
          mythicSkins.includes(skin),
        ).length;
        const price = getPrice(
          discreteBlueEssence,
          isHandleveled,
          rank,
          division,
          isPremium,
          isOldStock,
          mythicCount,
          prices,
        );
        return [...o, price];
      });

      // sort output by price (asc)
      output.sort((a, b) => a[a.length - 1] - b[b.length - 1]);

      //Slice 100 output and add interested skins and tags
      output = output.slice(0, 100).map((o) => {
        // Convert array product to dict
        const [
          id,
          region,
          discreteBlueEssence,
          orangeEssence,
          skins,
          permanentSkins,
          ownedSkins,
          discreteLevel,
          isHandleveled,
          rank,
          division,
          flashKey,
          soloWins,
          soloLosses,
          isPremium,
          date,
          price,
        ] = o;

        const dateLastPlayed = getLastPlayedDateObject(date);

        const product = {
          id,
          region,
          discreteBlueEssence,
          orangeEssence,
          skins,
          permanentSkins,
          ownedSkins,
          discreteLevel,
          isHandleveled,
          rank,
          division,
          flashKey,
          soloWins,
          soloLosses,
          isPremium,
          dateLastPlayed,
          isOldStock: getIsOldStock(dateLastPlayed, prices),
        };

        // important to convert empty string to null
        // because it raises errors when sending data to drf
        // when creating cart item
        if (product.soloWins === "") product.soloWins = null;
        if (product.soloLosses === "") product.soloLosses = null;

        const productSkins = [
          ...product.skins,
          ...product.permanentSkins,
          ...product.ownedSkins,
        ];
        const mythicCount = productSkins.filter((skin) =>
          mythicSkins.includes(skin),
        ).length;

        product["price"] = price;

        const [tags, label_tags] = getProductTags(
          product,
          filter,
          mythicCount,
          prices,
        );
        product["tags"] = tags;
        product["label_tags"] = label_tags;

        const thumbnailSkins =
          interestedSkins.length > 0
            ? productSkins.filter((skin) => interestedSkins.includes(skin))
            : productSkins;

        const [thumbnail, thumbnailSkin] = getThumbnail(
          skinShards,
          thumbnailSkins,
        );
        product["thumbnail"] = thumbnail;
        product["relevantSkins"] = [...interestedSkins, ...[thumbnailSkin]];

        return product;
      });
      setProducts(output);
    },
    [prices],
  );

  useEffect(() => {
    const load = async (signal) => {
      try {
        setFetching(true);
        const products_pathname = "/api/products/";

        const products_response = await fetch(products_pathname, { signal });

        if (products_response.ok) {
          const output = await products_response.json();
          output.sort((a, b) => a[2] - b[2]);
          setAllProducts(output);
        }
        setFetching(false);
      } catch (reason) {
        if (reason.name === "AbortError") return;
        setFetching(false);
      }
    };

    document.title = `${displayName} | Search Accounts with Free Skin Shards`;
    const controller = new AbortController();
    load(controller.signal);
    return () => controller.abort();
  }, []);

  useEffect(() => {
    setLoading(true);
    showResults(allProducts, skinShards, filter);
    const timer = setTimeout(() => setLoading(false), 500);
    return () => clearTimeout(timer);
  }, [allProducts, skinShards, filter, showResults]);

  const renderProducts = () => (
    <div className="product-card-group">
      {products.map((product, index) => (
        <div
          style={{ display: "flex", alignSelf: "stretch" }}
          key={product.id}
          data-aos="fade-up"
          data-aos-duration={500}
        >
          <ProductCard product={product} alt="product-image" />
        </div>
      ))}
    </div>
  );

  const shouldReferToLolaccshop = () => {
    const newAccountType = query.getAll("account-tier");
    const isSmurfSkins = leagueShopMode === "smurfskins";

    return newAccountType.includes("MYTHIC") && isSmurfSkins;
  };

  return (
    <Segment basic className="buy-account-page">
      <div className="buy-account-container">
        <div className="sidebar">
          <Sidebar
            onChange={(filter) => setFilter(filter)}
            path={"/buy-account/"}
          />
        </div>

        {loading || fetching ? (
          <div className="product-card-group">
            <ProductCardPlaceholder />
            <ProductCardPlaceholder />
            <ProductCardPlaceholder />
            <ProductCardPlaceholder />
            <ProductCardPlaceholder />
            <ProductCardPlaceholder />
            <ProductCardPlaceholder />
            <ProductCardPlaceholder />
          </div>
        ) : products.length === 0 ? (
          shouldReferToLolaccshop() ? (
            <NoMythicSearchResults />
          ) : (
            <NoSearchResults isBuyAccountPage={true} />
          )
        ) : (
          renderProducts()
        )}
      </div>
    </Segment>
  );
}
