import React, { useContext, useEffect, useState } from "react";
import { Icon, Label, LabelGroup, Popup, Segment } from "semantic-ui-react";
import InfoPopup from "../../components/InfoPopup";
import ShowSkinsPopup from "../../components/ShowSkinsPopup";
import { rankedValues } from "../../constants";
import DarkModeContext from "../../contexts/DarkModeContext";
import {
  getRegion,
  calculateDaysDifference,
  calculateTimeRemaining,
} from "../../utils";

export default function BidCard({ bid, isActive, isTradeCard = false }) {
  const { isDarkMode } = useContext(DarkModeContext);

  const renderIconPopups = () => (
    <div className="product-card-icons">
      {bid.product.tags?.map((tag, index) => (
        <Popup
          key={index}
          trigger={
            tag.img ? (
              <img src={tag.img} className="product-icon" alt="icon" />
            ) : (
              <Icon
                className="product-icon"
                name={tag.icon || "tag"}
                style={{ color: tag.color || "#fdde81" }}
                size="large"
              />
            )
          }
          content={tag.description}
        />
      ))}
      {(bid.isUserHighestBidder || bid.isWinner) && (
        <Popup
          trigger={
            bid.isWinner ? (
              <Icon
                className="product-icon"
                name={"trophy"}
                style={{ color: "#fdde81" }}
                size="large"
              />
            ) : (
              <div>
                <Icon
                  fitted
                  className="product-icon"
                  name={"dollar sign"}
                  style={{ color: "#fdde81" }}
                  size="large"
                />
                <Icon
                  fitted
                  className="product-icon"
                  name={"angle double up"}
                  style={{ color: "#fdde81" }}
                  size="small"
                />
              </div>
            )
          }
          position="top right"
          content={
            bid.isWinner
              ? "Congratulations, you won the bid."
              : "You are currently the highest bidder"
          }
        />
      )}
    </div>
  );
  const renderLabelTags = () => (
    <LabelGroup className="product-card-labels">
      {bid.product.label_tags?.map((tag, index) => {
        if (tag.description) {
          return (
            <Popup
              key={index}
              trigger={
                <Label key={index} className={tag.color}>
                  {tag.icon && <Icon name={tag.icon} />}
                  {tag.label}
                </Label>
              }
              content={tag.description}
            />
          );
        }

        return (
          <Label key={index} className={tag.color}>
            {tag.icon && <Icon name={tag.icon} />}
            {tag.label}
          </Label>
        );
      })}
    </LabelGroup>
  );

  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(bid.endDate),
  );

  const warranty = calculateDaysDifference(
    bid.warrantyDate,
    bid.dateCompleted ?? bid.endDate,
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(bid.endDate));
    }, 60000); // Update every minute

    return () => {
      clearInterval(interval);
    };
  }, [bid.endDate]);

  return (
    <Segment.Group className={`product-card ${isDarkMode ? "dark-mode" : ""}`}>
      <Segment
        basic
        inverted={isDarkMode}
        className="product-card-image-wrapper "
      >
        <div className="product-card-icons-wrapper">
          {renderLabelTags()}
          {!isTradeCard && renderIconPopups()}
        </div>
        <div
          className="product-card-image"
          style={{
            backgroundImage: `linear-gradient(180deg, var(--gradient) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 80%, var(--gradient) 100%), url(${bid.product.thumbnail})`,
          }}
        ></div>
      </Segment>
      <Segment inverted={isDarkMode} className="product-details">
        <div className="product-header">
          <div className="product-description">
            <div>Region: {getRegion(bid.product.region)}</div>
            <div>Level: {bid.discreteLevel || 30}+</div>{" "}
            {/*no discretelevel in data */}
            <div className="be">
              Blue Essence:
              {bid.product.blueEssence <= 0
                ? " <10,000"
                : " " + bid.product.blueEssence.toLocaleString() + "+"}
              {bid.product.isHandleveled ? (
                <InfoPopup description="This account contains champion capsules." />
              ) : null}
            </div>
            <div>
              Orange Essence: {bid.product.orangeEssence.toLocaleString()}
            </div>
            {rankedValues.includes(bid.product.rank) && (
              <div>
                Ranked Wins/Losses: {bid.product.soloWins || " - "}/
                {bid.product.soloLosses || " - "}
              </div>
            )}
            {isActive && (
              <div>
                <Icon name={"clock"} />
                Ends in: {timeRemaining}
              </div>
            )}
            <div>
              Warranty:{" "}
              {bid.warrantyDate
                ? `${warranty} ${warranty === 1 ? "day" : "days"}`
                : "None"}
            </div>
            <div>
              Highest Bid:{" "}
              {bid.highestBidAmount ? `€${bid.highestBidAmount}` : "-"}
            </div>
            <div>Your Bid: {bid.amount ? `€${bid.amount}` : "-"}</div>
            <div>Bids Placed: {bid.totalBids}</div>
          </div>
        </div>
      </Segment>
      <Segment className="product-card-buttons" inverted={isDarkMode} basic>
        <ShowSkinsPopup
          skins={bid.product.skins}
          permanent_skins={bid.product.permanentSkins}
          owned_skins={bid.product.ownedSkins}
          relevantSkins={bid.product.relevantSkins}
        />
      </Segment>
    </Segment.Group>
  );
}
