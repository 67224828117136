import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Icon, Label, Search } from "semantic-ui-react";
import SkinContext from "../contexts/SkinContext";
import InfoPopup from "./InfoPopup";
import SearchRender from "./SearchRender";

const useQuery = () => new URLSearchParams(useLocation().search);

export default function SkinShardSearch({
  input,
  size,
  onChange,
  onClear,
  resultCount,
  info,
}) {
  const { loading, skinShards } = useContext(SkinContext);
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [mappedSkins, setMappedSkins] = useState({});

  const [value, setValue] = useState("");
  const timeoutRef = useRef();
  const query = useQuery();

  const handleSearchChange = (value) => {
    clearTimeout(timeoutRef.current);
    setValue(value);
    timeoutRef.current = setTimeout(() => {
      const normalizedValue = value.toLocaleUpperCase();
      const filtered = Object.values(mappedSkins).filter(
        (skin) => skin.title.toLocaleUpperCase().indexOf(normalizedValue) >= 0,
      );
      setResults(filtered.slice(0, resultCount));
      if (value === "") onClear();
      setSearching(false);
    }, 300);
  };

  const handleResultSelect = (value) => {
    setValue("");
    let selectedSkinsTemp = [
      ...new Set([...query.getAll("skin-shard"), value.id]),
    ];
    onChange(selectedSkinsTemp);
  };

  const handleSearchItemDelete = (value) => {
    let selectedSkinsTemp = [
      ...new Set(query.getAll("skin-shard").filter((item) => item !== value)),
    ];
    onChange(selectedSkinsTemp);
  };

  useEffect(() => {
    setMappedSkins(
      skinShards.reduce((mapped, skin) => ({ ...mapped, [skin.id]: skin }), {}),
    );
    setValue("");
  }, [skinShards]);

  return (
    <>
      <div className="search-item">
        <div className="skin-search-inner ">
          <Search
            {...(input && { input: input })}
            onResultSelect={(e, { result }) => {
              handleResultSelect(result);
            }}
            onSearchChange={(e, { value }) => {
              setSearching(true);
              handleSearchChange(value);
            }}
            size={size}
            results={results}
            value={loading ? "Fetching Skins..." : value}
            loading={searching}
            disabled={loading}
            resultRenderer={SearchRender}
            fluid
          />
        </div>
        {info ? (
          <InfoPopup
            description={
              query.getAll("skin-shard").length > 0 ? (
                <div className="info-popup">
                  Filtering accounts containing following skins:
                  <ul>
                    {query.getAll("skin-shard").map((skin) => (
                      <li key={skin}>{mappedSkins[skin]?.title}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                "Filer one or more skins of your choice."
              )
            }
          />
        ) : null}
      </div>

      {Object.keys(mappedSkins).length > 0 &&
      query.getAll("skin-shard").length > 0 ? (
        <Label.Group className="search-items">
          {query.getAll("skin-shard").map((skin, index) => (
            <Label key={index}>
              {mappedSkins[skin]?.title}
              <Icon
                name="delete"
                onClick={() => handleSearchItemDelete(skin)}
              />
            </Label>
          ))}
        </Label.Group>
      ) : null}
    </>
  );
}

SkinShardSearch.defaultProps = {
  resultCount: 7,
  onChange: () => {},
  onClear: () => {},
  info: false,
};
