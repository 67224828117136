import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, Form, Input } from "semantic-ui-react";
import SkinShardSearch from "../../components/SkinShardSearch";
const regions = require("../../json/regions.json");

export default function HomePageSearch({ size }) {
  const history = useHistory();
  const [region, setRegion] = useState("EUW");
  const handleRegionChange = (value) => {
    setRegion(value);
  };

  return (
    <div className="homepage-search-group">
      <Form size={size} className="homepage-search-region">
        <Dropdown
          selection
          fluid
          options={regions}
          value={region}
          onChange={(e, { value }) => handleRegionChange(value)}
          placeholder="Select region"
        />
      </Form>
      <div style={{ width: "100%" }}>
        <SkinShardSearch
          input={
            <Input
              fluid
              size={size}
              type="text"
              className="homepage-search-input"
              placeholder="Find you favorite skin"
            />
          }
          resultCount={7}
          onChange={(value) =>
            history.push(
              `/buy-account/?region=${region}&skin-shard-option=specific&skin-shard=${value[0]}`,
            )
          }
        />
      </div>
    </div>
  );
}
