import React, { useContext } from "react";
import { Placeholder, Segment } from "semantic-ui-react";

import DarkModeContext from "../../contexts/DarkModeContext";

export default function CartItemSkeleton() {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <Segment inverted={isDarkMode} className="cart-item-skeleton">
      <Placeholder inverted={isDarkMode}>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
  );
}
