import { useContext } from "react";
import { Header, Icon } from "semantic-ui-react";
import DarkModeContext from "../contexts/DarkModeContext";

export default function NoResultsFound({ title = "No results found." }) {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <div className="no-search-results">
      <Header inverted={isDarkMode} icon>
        <Icon name="frown outline" />
        {title}
      </Header>
    </div>
  );
}
