import React from "react";
import { Loader, Segment } from "semantic-ui-react";

export default function AuthLoader() {
  return (
    <Segment className="my-account-segment" basic>
      <Loader inline active />
    </Segment>
  );
}
