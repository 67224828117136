import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Icon, Label, Search } from "semantic-ui-react";
import SkinContext from "../contexts/SkinContext";
import InfoPopup from "./InfoPopup";
import SearchRender from "./SearchRender";

const championMapping = {
  MonkeyKing: "Wukong",
};

const getMappingChampion = (value) =>
  value in championMapping ? championMapping[value] : value;

const getMappedChampion = (value) =>
  Object.keys(championMapping).find((key) => championMapping[key] === value) ||
  value;

const useQuery = () => new URLSearchParams(useLocation().search);

export default function ChampionSkinShardSearch({
  input,
  onChange,
  onClear,
  resultCount,
}) {
  const { loading, skinShards } = useContext(SkinContext);
  const [searching, setSearching] = useState(false);
  const [champions, setChampions] = useState([]);
  const [results, setResults] = useState([]);
  const [value, setValue] = useState("");
  const [selectedChampions, setSelectedChampions] = useState([]);
  const timeoutRef = useRef();
  const query = useRef(useQuery());

  const handleSearchChange = (value) => {
    clearTimeout(timeoutRef.current);
    setValue(value);
    timeoutRef.current = setTimeout(() => {
      const normalizedValue = value.toUpperCase();
      const filtered = champions.filter(
        (champion) =>
          champion.title.toUpperCase().indexOf(normalizedValue) !== -1,
      );
      setResults(filtered.slice(0, resultCount));
      if (value === "") onClear();
      setSearching(false);
    }, 300);
  };

  const handleResultSelect = (value) => {
    setValue("");
    let selectedChampionsTemp = [
      ...new Set([...selectedChampions, getMappedChampion(value)]),
    ];
    setSelectedChampions(selectedChampionsTemp);
    onChange(selectedChampionsTemp);
  };

  const handleSearchItemDelete = (value) => {
    let selectedChampionsTemp = [
      ...new Set(selectedChampions.filter((item) => item !== value)),
    ];
    setSelectedChampions(selectedChampionsTemp);
    onChange(selectedChampionsTemp);
  };

  useEffect(() => {
    setChampions(
      [...new Set(skinShards.map((item) => item.description))].map((item) => {
        const mappedChampion = getMappingChampion(item);
        return { title: mappedChampion ? mappedChampion : item };
      }),
    );

    const searchedChampions = query.current.getAll("champion");
    if (searchedChampions.length > 0) {
      setSelectedChampions(searchedChampions);
      return;
    }
    setValue("");
  }, [skinShards]);

  return (
    <>
      <div className="search-item">
        <div className="skin-search-inner ">
          <Search
            {...(input && { input: input })}
            onResultSelect={(e, { result }) => {
              handleResultSelect(result.title);
            }}
            onSearchChange={(e, { value }) => {
              setSearching(true);
              handleSearchChange(value);
            }}
            results={results}
            value={loading ? "Fetching Champions..." : value}
            loading={searching}
            disabled={loading}
            resultRenderer={SearchRender}
          />
        </div>
        <InfoPopup
          description={
            selectedChampions.length > 0 ? (
              <div className="info-popup">
                Filtering accounts containing following champions:
                <ul>
                  {selectedChampions.map((skin) => (
                    <li key={skin}>{skin}</li>
                  ))}
                </ul>
              </div>
            ) : (
              "Filer one or more champions of your choice."
            )
          }
        />
      </div>

      {selectedChampions.length > 0 ? (
        <Label.Group className="search-items">
          {selectedChampions.map((champion) => (
            <Label>
              {getMappingChampion(champion)}
              <Icon
                name="delete"
                onClick={() => handleSearchItemDelete(champion)}
              />
            </Label>
          ))}
        </Label.Group>
      ) : null}
    </>
  );
}

ChampionSkinShardSearch.defaultProps = {
  resultCount: 5,
  onChange: () => {},
  onClear: () => {},
};
